import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import '../../../assets/css/DataTable.css';
import { CambiarFormatoFecha, GetDates, SumarDias, NuevoValidarMesCerrado, weekNext, obtenerHoraMaximaPorFecha } from '../../Funciones';
import Cookies from 'universal-cookie';
import Services from '../../../service/Services';
import axios from "axios";
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';

const cookies = new Cookies();


const ModalSelectedDates = ({ styleProp, enviarMensajeProp, label, dataProp, dataCopiedRegisterProp, setDataCopiedRegisterProp,
    setDisabledButtonYesProp, setDisabledButtonNoProp, disabledProp, copiarActividadesProp, setShowElementsProp, setDisabledBtnNoProp, restartDataProp,
    setInputActividadProp, setInputHorasProp, setInputTicketProp, setSelectedDateProp, validateChangeProp,
    optionSelectedProp, idProp, setIdProp, uniqueDataProp, dataTypeActivityProp, detailPendingActivitiesProp, hoursPendigProp, objectDataProp,
    setActivitiesProp, rowIndexProp, setNewInputHoursProp,
    newDescriptionActivityProp, fechaErrorProp, setSelectedDatesProp, setOptionSelectedProp,
    newErrorHoursProp, newMessageHoursProp, setNewErrorHoursProp, inputHoursProp,
    setMessageHoursProp, setErrorHoursProp, setPermissionEditProp, setLabelProp, setDataProp,
    setActividadBlurProp, newErrorVacacionesProp, inputActividadProp, idActividadProp, dataActivityProp }) => {

    const toast = useRef(null);


    const [displayBasic, setDisplayBasic] = useState(false);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData(false)
    }

    const onHideSave = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const usuario = cookies.get('usuario')
    const [closures, setClosures] = useState([]);

    const getServices = new Services();

    const [servicesUsers, setServicesUsers] = useState([]);
    const [tipoNovedades, setTipoNovedades] = useState([]);


    useEffect(() => {
        if (displayBasic === true) {
            getServices.getUsuariosWithIdNameAddActivity().then(data => setServicesUsers(data))

            getServices.getTipoNovedad().then(data => {
                if (data !== undefined) {
                    setTipoNovedades(data)
                }
            });
            getServices.getCierres().then(data => {
                if (data !== undefined) {
                    setClosures(data)
                }
            });
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (detailPendingActivitiesProp !== undefined) {
            if (detailPendingActivitiesProp === true) {
                if (hoursPendigProp !== undefined) {
                    setInpuntHours({
                        hours: hoursPendigProp
                    })
                }
            }
        } else {
            if (copiarActividadesProp === true) {

                setInpuntHours({
                    hours: inputHoursProp
                })
                setInpuntActivity({
                    activity: inputActividadProp
                })
            }

        }

    }, [detailPendingActivitiesProp, hoursPendigProp, inputHoursProp, inputActividadProp, copiarActividadesProp])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dataCopiedRegisterProp) {
            setData(dataCopiedRegisterProp.filter(obj => obj.rowIndex === rowIndexProp))
        }
    }, [dataCopiedRegisterProp, rowIndexProp, dataProp])

    useEffect(() => {
        if (copiarActividadesProp === false) {
            setData([])
        }
    }, [restartDataProp, copiarActividadesProp])

    const [data, setData] = useState([]);

    const [selectedDates, setSelectedDates] = useState(null);
    const [dateError, setDateError] = useState(false)
    const [dateErrorMessage, setDateErrorMessage] = useState('')
    const selectedArrayDates = (arr) => {
        if (arr !== null) {
            var arrDatesError = []
            arr.forEach(element => {
                var validateDate = NuevoValidarMesCerrado(arr, minFechaCierre, disabledDates)
                if (validateDate.setFechaError === true) {
                    arrDatesError.push(element)
                }
            })
            if (arrDatesError.length !== 0) {
                setDateError(true)
                setDateErrorMessage('La fecha ingresada pertenece a un mes cerrado')
            } else {
                setDateError(false)
                setDateErrorMessage('')
            }
            var sendData = arr.map((item, i) => {
                setIdProp(idProp + i)
                return {
                    actualizadopor: '',
                    aplicacion: {
                        id: objectDataProp.aplicacion.id
                    },
                    areanegocio: {
                        id: objectDataProp.areanegocio.id
                    },
                    cliente: dataProp.cliente,
                    colaborador: dataProp.colaborador,
                    coordinadocon: '',
                    creadopor: usuario,
                    descripcionactividad: dataProp.descripcionactividad,
                    esadicional: 0,
                    estado: 1,
                    fecha: CambiarFormatoFecha(item),
                    fechaactualizado: '',
                    fechacorreoelec: '',
                    fechacreacion: new Date(),
                    idempresa: dataProp.idempresa,
                    notas: dataProp.notas,
                    numerohoras: dataProp.numerohoras,
                    proyecto: {
                        id: objectDataProp.proyecto.id
                    },
                    ticket: dataProp.ticket,
                    tipoActividad: {
                        id: objectDataProp.tipoActividad.id
                    },
                    id: idProp + i
                }
            })
            if (optionSelectedProp !== undefined) {
                optionSelectedProp.editorCallback(JSON.stringify({
                    selected: 'No',
                    data: sendData
                }))
            }
            setData(sendData)
        }
    }


    const onSelectedDatesChange = (e) => {
        if (label === 'No') {
            setSelectedDates(e.value)
            selectedArrayDates(e.value)
        } else {
            setSelectedDates([e.value])
            if (e.value) {
                var validateDate = NuevoValidarMesCerrado([e.value], minFechaCierre, disabledDates)
                setDateError(validateDate.setFechaError)
                setDateErrorMessage(validateDate.setMensajeFecha)
                var repeatDate = []
                data.forEach(element => {
                    if (element.fecha === CambiarFormatoFecha(e.value)) {
                        repeatDate.push(element.fecha)
                    }
                })
            }
        }

        if (label === 'Si' && copiarActividadesProp === false) {
            setSelectedDateProp([e.value])
        }

    }

    const [inputActivity, setInpuntActivity] = useState({
        activity: ''
    })

    const [actividadBlur, setActividadBlur] = useState(true)

    const onInputActivityChange = (event) => {
        setActividadBlur(false)

        if (label === 'Si' && copiarActividadesProp === false) {
            setActividadBlurProp(false)
            setInputActividadProp({
                actividad: event.target.value
            })
        }

        setInpuntActivity({
            ...inputActivity,
            [event.target.name]: event.target.value
        })
    }

    const onActividadBlur = (event) => {
        setActividadBlur(true)
        if (label === 'Si' && copiarActividadesProp === false) {
            setActividadBlurProp(true)
        }
    }

    const [inputHours, setInpuntHours] = useState({
        hours: ''
    })

    const onInputHoursChange = (event) => {
        const newValue = event.target.value;
        if ((newValue.match(/\./g) || []).length > 1 || newValue.startsWith('.')) {
            return;
        }
        setInpuntHours({
            ...inputHours,
            [event.target.name]: event.target.value
        })
        if (copiarActividadesProp === false) {
            setNewInputHoursProp({
                ...inputHours,
                [event.target.name]: event.target.value
            })
        }
    }
    // validacion de de actividades duplicadas
    const [showErrorDataDuplicate, setShowErrorDataDuplicate] = useState(false);
    const [menssageDataDuplicate, setMenssageDataDuplicate] = useState('');

    const [newErrorHours, setNewErrorHours] = useState(false)
    const [newMessageHours, setNewMessageHours] = useState('')
    const [newErrorVacaciones, setNewErrorVacaciones] = useState(false)
    const [newMessageErrorVacaciones, setNewMessageErrorVacaciones] = useState('')
    useEffect(() => {

        const validarFechasCruzadasConVacaciones = (tipoNovedades, dataActivity) => {
            if (tipoNovedades.length !== 0) {
                let arrayFechas = []//Obtener listado de fechas en la que hay novedades
                const filterVacaciones = tipoNovedades.filter(item => {
                    return item.isVacaciones === true
                })

                dataActivity?.forEach(i => {
                    filterVacaciones.forEach(element => {
                        if (element?.tipoActividad?.id === i.tipoActividad?.id) {
                            arrayFechas.push(i.fecha)
                        }
                    })
                })

                if (arrayFechas.length === 1) {
                    const fecha = arrayFechas[0];
                    setNewErrorVacaciones(true)
                    setNewMessageErrorVacaciones(`La siguiente fecha ya está seleccionada como novedad de tiempo completo: ${fecha}. Modifique la fecha`)

                } else if (arrayFechas.length > 1) {
                    const fechas = arrayFechas.join(', ');
                    setNewErrorVacaciones(true)
                    setNewMessageErrorVacaciones(`Las siguientes fechas ya están seleccionadas como novedad de tiempo completo: ${fechas}. Modifique las fechas.`)

                } else {
                    setNewErrorVacaciones(false);
                    setNewMessageErrorVacaciones('')
                }
            }
        }
        if (actividadBlur === true) {
            if (selectedDates !== null && selectedDates.length !== 0) {

                var orderDates = selectedDates.sort((a, b) => a - b);
                const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/consultListActivity'
                const json = JSON.stringify({
                    esadicional: "0",
                    fecFinal: CambiarFormatoFecha(orderDates[orderDates.length - 1]),
                    fecInicial: CambiarFormatoFecha(orderDates[0]),
                    usuario: usuario
                })
                var fechaConvertida = selectedDates.map(item => {
                    return item !== null ? CambiarFormatoFecha(item) : ''
                })
                axios.post(url, json,
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        }
                    })
                    .then(response => {
                        let excedioHoras = false
                        let tieneMaximaCero = false;
                        const historicoHoras = sessionStorage.getItem('historicoHoras')
                        const newHistoricoHoras = JSON.parse(historicoHoras)
                        let horasPorFecha = {};
                        let newData = []
                        const dataConcat = label === 'No' ? response.data : response.data.concat(data)

                        if (parseFloat(label === 'Si' ? inputHours.hours : inputHoursProp) === parseFloat(0) && parseFloat(label === 'Si' ? inputHours.hours : inputHoursProp) === parseFloat(0.0)) {
                            setNewErrorHours(true)
                            setNewMessageHours('Las horas registradas deben ser mayor a 0')
                            setErrorHoursProp(true)
                            setMessageHoursProp('Las horas registradas deben ser mayor a 0')
                            /* setPermissionEditProp(true) */
                        } else {
                            fechaConvertida.forEach(y => {
                                const filterDataConcat = dataConcat.filter(obj => {
                                    return obj.fecha === y
                                });
                                if (filterDataConcat.length > 0) {
                                    filterDataConcat.forEach(element => {
                                        newData.push(element)
                                    })
                                }
                            })
                            validarFechasCruzadasConVacaciones(tipoNovedades, newData)
                            //Validar horas máximas cuando hay en fechas donde hay actividades registradas
                            if (newData.length > 0) {

                                newData.forEach(element => {
                                    const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(newHistoricoHoras, [element.fecha])

                                    if (!horasPorFecha.hasOwnProperty(element.fecha)) {
                                        horasPorFecha[element.fecha] = 0;
                                    }

                                    horasPorFecha[element.fecha] += parseFloat(element.numerohoras);

                                    if (parseFloat(horasMaximaPorFecha[element.fecha]) === 0) {
                                        tieneMaximaCero = true;
                                    }
                                    if ((tieneMaximaCero && horasPorFecha[element.fecha] + parseFloat(label === 'Si' ? inputHours.hours : inputHoursProp) > parseFloat(24)) ||
                                        (!tieneMaximaCero && horasPorFecha[element.fecha] + parseFloat(label === 'Si' ? inputHours.hours : inputHoursProp) > parseFloat(horasMaximaPorFecha[element.fecha]))) {

                                        excedioHoras = true;
                                    }

                                    if (excedioHoras === true) {
                                        /* setPermissionEditProp(true) */
                                        setNewErrorHours(true)
                                        setNewMessageHours('No se pueden registrar más de las horas máximas parametrizadas')
                                    } else {
                                        /* setPermissionEditProp(false) */
                                        setNewErrorHours(false)
                                        setNewMessageHours('')
                                    }
                                })
                            } else {
                                //Obtener horas Máximas segun las fechas
                                fechaConvertida.forEach(fecha => {
                                    const horasMaximaPorFecha = obtenerHoraMaximaPorFecha(newHistoricoHoras, [fecha])

                                    //Validar horas máximas cuando no hay en fechas donde hay actividades registradas
                                    if (parseFloat(horasMaximaPorFecha[fecha]) > parseFloat(0)) {

                                        if (parseFloat(label === 'Si' ? inputHours.hours : inputHoursProp) > parseFloat(horasMaximaPorFecha[fecha])) {
                                            excedioHoras = true
                                        }
                                        if (excedioHoras === true) {
                                            /* setPermissionEditProp(true) */
                                            setNewErrorHours(true)
                                            setNewMessageHours('No se pueden registrar más de las horas máximas parametrizadas')
                                        } else {
                                            /* setPermissionEditProp(false) */
                                            setNewErrorHours(false)
                                            setNewMessageHours('')
                                        }
                                    } else {
                                        if (parseFloat(label === 'Si' ? inputHours.hours : inputHoursProp) > parseFloat(24)) {
                                            setNewErrorHours(true)
                                            setNewMessageHours('No se pueden pasar de las 24 horas del día')
                                            /* setPermissionEditProp(true) */
                                        }
                                        else {
                                            setNewErrorHours(false)
                                            setNewMessageHours('')
                                            /* setPermissionEditProp(false) */
                                        }
                                    }
                                })
                            }
                        }


                        let dataDuplicate = []
                        var newArrayDataRegistered = response.data.map(item => {
                            return {
                                cliente: {
                                    id: item.cliente.id
                                },
                                proyecto: {
                                    id: item.proyecto.id
                                },
                                tipoActividad: {
                                    id: item.tipoActividad.id
                                },
                                areanegocio: {
                                    id: item.areanegocio.id
                                },
                                aplicacion: {
                                    id: item.aplicacion.id
                                },
                                fecha: item.fecha,
                                descripcionactividad: item.descripcionactividad.toLowerCase(),
                                ticket: item.ticket,
                                idObjetivo: item.idObjetivo,
                                idLogro: item.idLogro,
                                idEntregable: item.idEntregable,
                            }
                        })

                        var newArrData = selectedDates.map(e => {
                            return {
                                cliente: {
                                    id: dataProp.cliente.id
                                },
                                proyecto: {
                                    id: dataProp.proyecto.id
                                },
                                tipoActividad: {
                                    id: dataProp.tipoActividad.id
                                },
                                areanegocio: {
                                    id: dataProp.areanegocio.id
                                },
                                aplicacion: {
                                    id: dataProp.aplicacion.id
                                },
                                fecha: CambiarFormatoFecha(e),
                                descripcionactividad: label === 'No' ? newDescriptionActivityProp.toLowerCase() : inputActivity.activity.toLocaleLowerCase(),
                                ticket: dataProp.ticket,
                                idObjetivo: dataProp.idObjetivo,
                                idLogro: dataProp.idLogro,
                                idEntregable: dataProp.idEntregable,
                            }
                        })

                        newArrayDataRegistered.forEach(y => {
                            newArrData.forEach(i => {
                                if (JSON.stringify(i) === JSON.stringify(y)) {
                                    dataDuplicate.push(newArrData)
                                }
                            })

                        })

                        if (dataDuplicate.length !== 0) {
                            setShowErrorDataDuplicate(true)
                            setMenssageDataDuplicate('La actividad marcada es exactamente igual a una ya registrada, modifique los datos')
                        } else {
                            setShowErrorDataDuplicate(false)
                            setMenssageDataDuplicate('')
                        }
                    })
            }
        }

    }, [selectedDates, dataProp, newDescriptionActivityProp, inputActivity.activity, copiarActividadesProp, inputHours, inputHoursProp, label, data, actividadBlur, tipoNovedades])// eslint-disable-line react-hooks/exhaustive-deps

    const [inputTicket, setInpuntTicket] = useState({
        ticket: ''
    })

    const onInputTicketChange = (event) => {
        if (label === 'Si' && copiarActividadesProp === false) {
            setInputTicketProp({
                ticket: event.target.value
            })
        }
        setInpuntTicket({
            ...inputTicket,
            [event.target.name]: event.target.value
        })
    }

    const resetData = (clearData, modalReport) => {

        setSelectedDates(null)
        if (modalReport === true) {
            if (dataProp !== undefined) {
                /* if (dataProp.optionSelected !== '') {
                    var arrayData = JSON.parse(dataProp.optionSelected)
                    var newData = []
                    for (var i = 0; i < uniqueDataProp.length; i++) {
                        var equal = false;
                        for (var j = 0; j < arrayData.data.length & !equal; j++) {
                            if (uniqueDataProp[i]['id'] === arrayData.data[j]['id'] &&
                                uniqueDataProp[i]['descripcionactividad'] === arrayData.data[j]['descripcionactividad'])
                                equal = true;
                        }
                        if (!equal) newData.push(uniqueDataProp[i]);
                    }
                    setDataCopiedRegisterProp(newData)
                } */
            }
        }

        if (copiarActividadesProp === true) {

            setInpuntTicket({
                ticket: ''
            })
            setInpuntActivity({
                activity: ''
            })
            setInpuntHours({
                hours: ''
            })
            if (clearData !== false) {
                setDataCopiedRegisterProp([])
            }
            setData([])
            setDateError(false)
            setShowErrorDataDuplicate(false)
            setMenssageDataDuplicate('')
        } else {
            setInpuntTicket({
                ticket: ''
            })
            setInpuntActivity({
                activity: ''
            })
            setInpuntHours({
                hours: ''
            })
            setNewInputHoursProp('')
            setNewErrorHoursProp(false)
            setData([])
            if (clearData !== false) {
                setDataCopiedRegisterProp([])
            }
        }
    }


    // Función que agrega los datos a la tabla

    const AddData = () => {
        const newId = data.length > 0 ? Math.max(...data.map(item => item.id)) + 1 : 1;

        if (copiarActividadesProp === true) {

            /*  setIdProp(idProp + 1) */

            var obj = {
                actualizadopor: '',
                aplicacion: {
                    id: objectDataProp.aplicacion.id
                },
                areanegocio: {
                    id: objectDataProp.areanegocio.id
                },
                cliente: dataProp.cliente,
                colaborador: dataProp.colaborador,
                coordinadocon: '',
                creadopor: usuario,
                descripcionactividad: inputActivity.activity,
                esadicional: 0,
                estado: 1,
                fecha: CambiarFormatoFecha(selectedDates[0]),
                fechaactualizado: '',
                fechacorreoelec: '',
                fechacreacion: new Date(),
                idempresa: dataProp.idempresa,
                notas: dataProp.notas,
                numerohoras: inputHours.hours,
                proyecto: {
                    id: objectDataProp.proyecto.id
                },
                ticket: inputTicket.ticket,
                tipoActividad: {
                    id: objectDataProp.tipoActividad.id
                },
                id: newId,
                idObjetivo: objectDataProp.idObjetivo,
                idLogro: objectDataProp.idLogro,
                idEntregable: objectDataProp.idEntregable

            }
            resetData(false)
            setData([...data, obj])
            if (optionSelectedProp !== undefined) {
                optionSelectedProp.editorCallback(JSON.stringify({
                    selected: 'Si',
                    data: [...data, obj]
                }))
            }
        } else {
            /* setIdProp(idProp + 1) */
            var obj2 = {
                descripcionactividad: inputActivity.activity,
                fecha: CambiarFormatoFecha(selectedDates[0]),
                numerohoras: inputHours.hours,
                ticket: inputTicket.ticket,
                id: newId
                /* id: idProp + 1 */


            }
            resetData(false, false)
            setData([...data, obj2])
        }
    }

    useEffect(() => {
        setData(data)
        setDataProp(data)

    }, [data, copiarActividadesProp])// eslint-disable-line react-hooks/exhaustive-deps

    const DeleteData = (rowData) => {
        var newData = data.filter((object) => object.id !== rowData.id)
        setData(newData)
    }

    const SaveData = (name) => {
        onHideSave(name)
        var arrData = []

        data.forEach(element => {
            arrData.push(element)
        })


        if (label === 'Si') {
            setDataCopiedRegisterProp(arrData)
        }
        /* if (copiarActividadesProp === true) {
            setSelectedDatesProp(selectedDates)
            if (enviarMensajeProp === 'selecciono si') {
                setDisabledButtonNoProp(true)
            } else {
                setOptionSelectedProp('No')
                setDisabledButtonYesProp(true)
            }
        } */
        if (validateChangeProp !== undefined) {
            validateChangeProp(true)
        }
    }

    const deleteService = (rowData) => {
        return (
            <Button icon="pi pi-trash" onClick={() => DeleteData(rowData)} className="p-button-danger" />
        )
    }

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            resetData(false, true)
            if (optionSelectedProp !== undefined) {
                optionSelectedProp.editorCallback('')
            }
            dialogFuncMap[`${name}`](false);
            setDisplayBasic(false)

        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>Si cancela la parametrización, los datos se borrarán, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {permissionRegister ?
                    <>
                        <ModalBack />
                        {
                            dateError || showErrorDataDuplicate || errorDateStartUserSession || newErrorVacaciones ?
                                ''
                                :
                                <Button label="Aceptar" icon="pi pi-check" onClick={() => SaveData(name)} autoFocus />
                        }
                        {
                            <div className="p-fluid p-formgrid p-grid">
                                <form style={{ display: 'contents' }} >
                                    <div className="p-field p-col-12 p-md-12">
                                        {showErrorDataDuplicate === true ?
                                            <Message severity="error" text={menssageDataDuplicate} />
                                            :
                                            ""
                                        }
                                    </div>
                                </form>
                            </div>
                        }
                    </>
                    :
                    <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => {
                        onHide(name)
                        resetData(false)
                    }} autoFocus />
                }

            </div>
        );
    }


    // Validaciones

    var ticketError = false
    var messageTicket = ''
    if (inputTicket.ticket !== null) {
        if (dataTypeActivityProp !== undefined) {
            if (dataTypeActivityProp.tipoActividad.nombretipoactividad === 'Soporte-Requerimientos' || dataTypeActivityProp.tipoActividad.nombretipoactividad === 'Soporte-Incidencias') {
                ticketError = true
                messageTicket = 'Debes ingresar un ticket'
                if (inputTicket.ticket !== '') {
                    if (inputTicket.ticket.length < 2) {
                        ticketError = true
                        messageTicket = 'El ticket debe tener más de 2 dígitos'
                    } else if (inputTicket.ticket.length > 50) {
                        ticketError = true
                        messageTicket = 'El ticket debe tener menos de 50 dígitos'
                    } else {
                        ticketError = false
                        messageTicket = ''
                    }
                }
            } else {
                if (inputTicket.ticket !== '') {
                    if (inputTicket.ticket.length < 2) {
                        ticketError = true
                        messageTicket = 'El ticket debe tener más de 2 dígitos'
                    } else if (inputTicket.ticket.length > 50) {
                        ticketError = true
                        messageTicket = 'El ticket debe tener menos de 50 dígitos'
                    } else {
                        ticketError = false
                        messageTicket = ''
                    }
                }
            }
        }

    }

    var activityError = false
    var menssageActivity = ''
    if (inputActivity.activity?.length < 10 && inputActivity.activity !== '') {
        activityError = true
        menssageActivity = 'La descripción es muy corta'
    } else if (inputActivity.activity?.length > 300) {
        activityError = true
        menssageActivity = 'La descripción es demasiado larga '
    }

    var permissionRegister = false

    if (data.length !== 0) {
        if (copiarActividadesProp === false && newErrorHoursProp === false) {
            permissionRegister = true
        } else if (copiarActividadesProp === true && newErrorHours === false) {
            permissionRegister = true
        } else {
            permissionRegister = false
        }

    }

    // validar Mes cerrado

    var idUsuario = cookies.get('idUsuario')
    const resultUser = servicesUsers.filter(item => {
        return parseInt(item.idUsuario) === parseInt(idUsuario)
    })

    var mesAbierto = []
    if (resultUser.length !== 0) {
        resultUser.forEach(element => {
            mesAbierto = closures.filter(function (objeto) {
                var id = element.blnAddActivitivy
                return objeto.id === id
            })
        })
    }
    const mesesCerrados = closures.filter(function (objeto) {
        return objeto.estado === 'C'
    })

    var fechaCierre = []

    mesesCerrados.forEach(element => {
        fechaCierre.push(element.fechaFinalCierre)
    })

    if (mesAbierto.length === 0) {
        closures.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    } else {
        mesesCerrados.forEach(element => {
            fechaCierre.push(element.fechaFinalCierre)
        })
    }
    let arrayFechasCierres = fechaCierre.map((item) => new Date(item))
    var fechaUltimoCierre = new Date(Math.max.apply(null, arrayFechasCierres))
    var getMesAbierto = {}

    mesAbierto.forEach(element => {
        getMesAbierto = {
            estado: element.estado,
            fechaFinalCierre: element.fechaFinalCierre,
            fechaInicialCierre: element.fechaInicialCierre
        }
    })

    var convertirFechaFinal = new Date(getMesAbierto.fechaFinalCierre)
    var newFechaFinalCierre = SumarDias(convertirFechaFinal, +2)

    var convertirFechaInicial = new Date(getMesAbierto.fechaInicialCierre)
    var newFechaInicialCierre = SumarDias(convertirFechaInicial, +1)

    var dateArray = GetDates(newFechaFinalCierre, (fechaUltimoCierre).addDays(1));

    closures.sort(function (a, b) {
        var textA = a.id;
        var textB = b.id;
        return textA - textB
    })

    var minFechaCierre
    var disabledDates

    if (mesAbierto.length !== 0) {
        minFechaCierre = newFechaInicialCierre
        disabledDates = dateArray

    } else {
        minFechaCierre = SumarDias(fechaUltimoCierre, +1)
        disabledDates = []
    }

    // Sé valida que las fechas de registrar actividad no sean menores que la fecha de ingreso del usuario en sessión
    const [errorDateStartUserSession, setErrorDateStartUserSession] = useState(false)
    const [errorMessageDateStartUserSession, setErrorMessageDateStartUserSession] = useState('')
    useEffect(() => {
        const dateStartUserSession = cookies.get('fechaIngreso')

        if (selectedDates !== null) {
            let arrDate = []
            selectedDates.forEach(e => {
                if (e !== null) {
                    if (CambiarFormatoFecha(e) < dateStartUserSession) {
                        arrDate.push(e)
                    }
                }
            })
            if (arrDate.length !== 0) {
                setErrorDateStartUserSession(true)
                setErrorMessageDateStartUserSession(`No puedes registrar actividades con fecha menor a su fecha de ingreso, la cuál es ${dateStartUserSession}`)
            } else {
                setErrorDateStartUserSession(false)
                setErrorMessageDateStartUserSession('')
            }
        }

    }, [selectedDates])

    const [errorEntero, setErrorEntero] = useState(false)
    const [messageEntero, setMessageEntero] = useState('')
    useEffect(() => {

        if (inputHours.hours !== '') {
            const validarEntero = isValidNumber(inputHours.hours)
            if (validarEntero === false) {
                setErrorEntero(true)
                setMessageEntero('Debe ingresar números enteros o decimales')
            } else {
                setErrorEntero(false)
                setMessageEntero('')
            }
        }
        function isValidNumber(value) {
            // Verifica si el valor es un número válido
            return /^\d+(\.\d+)?$/.test(value);
        }
    }, [inputHours.hours])// eslint-disable-line react-hooks/exhaustive-deps

    var permissionAddData = false
    if (label === 'No') {
        if (selectedDates !== null) {
            if (selectedDates.length !== 0) {
                permissionRegister = true
            }
        }
    } else {
        if (selectedDates !== null && inputHours.hours !== '' && inputActivity.activity !== '' && dateError === false && activityError === false
            && ticketError === false && showErrorDataDuplicate === false && errorDateStartUserSession === false) {
            if (copiarActividadesProp === true) {
                if (newErrorHours === false && errorEntero === false && newErrorVacaciones === false) {

                    permissionAddData = true
                }
            } else {
                if (fechaErrorProp === false && newErrorHoursProp === false && errorEntero === false && newErrorVacacionesProp === false) {
                    permissionAddData = true
                }
            }

        }
    }
    var maxDates = weekNext()

    return (
        <>

            <Button label={label} style={styleProp} icon="pi pi-external-link" disabled={disabledProp} onClick={() => {
                onClick('displayBasic')
                if (copiarActividadesProp === true) {
                    if (label === 'Si') {
                        setErrorDateStartUserSession(false)
                        /* setDisabledButtonNoProp(true) */
                    } else {
                        setDisabledButtonYesProp(true)
                    }
                } else {
                    setLabelProp(label)
                    setShowElementsProp(false)
                    setDisabledBtnNoProp(true)
                    setInputActividadProp({
                        actividad: ''
                    })
                    setInputHorasProp({
                        horas: ''
                    })
                    setInputTicketProp({
                        ticket: ''
                    })
                    setSelectedDateProp([])
                }
            }} />
            <Dialog className="modal" header="Parametrizar Fechas" visible={displayBasic} modal style={{ width: '58vw' }} breakpoints={{ '960px': '75vw', '640px': '75vw' }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <Toast ref={toast} />

                <div className="p-fluid p-formgrid p-grid">
                    {label === "No" ?
                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Seleccionar fechas<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una o más fechas" value={selectedDates} onChange={(e) => onSelectedDatesChange(e)} selectionMode="multiple"
                                    dateFormat="dd-mm-yy" locale="es" className={dateError || errorDateStartUserSession || newErrorVacaciones ? "p-invalid" : ""} maxDate={maxDates}

                                />
                                <p className="mensajeError">{newErrorVacaciones ? newMessageErrorVacaciones : ""}</p>
                                <p className="mensajeError">{dateError ? dateErrorMessage : ""}</p>
                                <p className="mensajeError">{errorDateStartUserSession ? errorMessageDateStartUserSession : ""}</p>
                            </div>
                        </form>
                        :
                        <div style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-6">
                                <label className="labels" >Seleccionar fechas<span className="require">*</span></label>
                                <Calendar placeholder="Seleccione una o más fechas" value={selectedDates} onChange={(e) => onSelectedDatesChange(e)}
                                    dateFormat="dd-mm-yy" locale="es" className={dateError || errorDateStartUserSession || newErrorVacaciones ? "p-invalid" : ""} maxDate={maxDates}

                                />
                                <p className="mensajeError">{newErrorVacaciones ? newMessageErrorVacaciones : ""}</p>
                                <p className="mensajeError">{dateError ? dateErrorMessage : ""}</p>
                                <p className="mensajeError">{errorDateStartUserSession ? errorMessageDateStartUserSession : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label className="labels">Req.Ticket</label>
                                <InputText id="ticket" type="text" name="ticket" keyfilter={/^[^<>*!=°":;_|(#$%&´¨)+-/?¡¿'{}]+$/} onChange={onInputTicketChange}
                                    placeholder="Registre un ticket" className={ticketError ? "p-invalid" : ""} value={inputTicket.ticket}

                                />
                                <p className="mensajeError">{ticketError ? messageTicket : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label className="labels" >Número Horas<span className="require">*</span></label>
                                <InputText id="hours" name="hours" keyfilter={/^[0-9]*\.?[0-9]*$/} onChange={onInputHoursChange}
                                    placeholder="Registre el número de horas" value={inputHours.hours} className={newErrorHoursProp || newErrorHours || errorEntero ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">
                                    {newErrorHoursProp === true && copiarActividadesProp === false ? newMessageHoursProp : ""}
                                    {newErrorHours === true && copiarActividadesProp === true ? newMessageHours : ''}{errorEntero ? messageEntero : ""}</p>
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Actividad<span className="require">*</span></label>
                                <div className="p-inputgroup">
                                    <InputTextarea rows={5} cols={30} value={inputActivity.activity} keyfilter={/^[^<>°|'{}]+$/}
                                        onChange={onInputActivityChange} onBlurCapture={onActividadBlur} name="activity" placeholder="Describa la Actividad que realizó"
                                        autoResize className={activityError ? "p-invalid" : ""}
                                    />
                                    {permissionAddData ?
                                        <Button icon="pi pi-plus" label='Agregar' className="btnAdd" onClick={() => AddData()} />
                                        :
                                        null
                                    }
                                </div>
                                <p className="mensajeError">{activityError ? menssageActivity : ""}</p>
                            </div>
                        </div>
                    }
                </div>
                {label === "No" ?
                    null
                    :
                    <div>
                        {
                            <div className="p-fluid p-formgrid p-grid">
                                <form style={{ display: 'contents' }} >
                                    <div className="p-field p-col-12 p-md-12">
                                        {showErrorDataDuplicate === true ?
                                            <Message severity="error" text={menssageDataDuplicate} />
                                            :
                                            ""
                                        }
                                    </div>
                                </form>
                            </div>
                        }
                        <div className="datatable-responsive-demo">
                            <div className="card">

                                <DataTable value={data} emptyMessage="No se registran datos" paginator rows={3}>
                                    <Column className="columna" field="fecha" header="Fecha" />
                                    <Column className="columna" field="ticket" header="Req.Ticket" />
                                    <Column className="columna" field="descripcionactividad" header="Actividad" />
                                    <Column className="columna" field="numerohoras" header="Horas" />
                                    <Column className="columna" header="Eliminar" body={deleteService} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                }
            </Dialog>

        </>
    )
}

export default ModalSelectedDates