import React, { useState, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import ConfirmBtn from "./ConfirmBtn";
import Services from "../../service/Services";
import { CambiarFormatoFecha, SumarDias } from "../Funciones";

const BtnActualizarContrato = ({ idProp, clientProp, contractProp, createdByProp, statusProp, creationDateProp, dateEndProp, dateStartProp, dateContractProp,
    objectContractProp, setRunRenderProp, runRenderProp }) => {

    const [serviceClients, setServiceClients] = useState([]);

    const getService = new Services()

    useEffect(() => {
        getService.getProyectosUsuarios().then(data => {
            var nameClients = []
            data.forEach(element => {
                var clients = {}
                clients.id = element.cliente.id
                clients.razonsocial = element.cliente.nombre
                nameClients.push(clients)
            })
            nameClients.push({
                id: parseInt(clientProp.id),
                razonsocial: clientProp.razonsocial
            })
            const clientsTable = {}
            const uniqueClients = nameClients.filter(function (object) {
                return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
            })
            setServiceClients(uniqueClients)
        })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const [displayBasic, setDisplayBasic] = useState(false);
    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetData()
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del input contrato

    const [inputContract, setInputContract] = useState({
        contract: ''
    })

    const onContractChange = (event) => {
        setInputContract({
            ...inputContract,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del input objeto del contrato

    const [inputObjectContract, setInputObjectContract] = useState({
        objectContract: ''
    })

    const onObjectContractChange = (event) => {
        setInputObjectContract({
            ...inputObjectContract,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        id: '',
        name: ''
    });

    const clients = serviceClients.map(item => {
        const av = { name: item.razonsocial, id: parseInt(item.id) }
        return av
    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    // calendar de fecha inicio vigencia

    const [dateStart, setDateStart] = useState(null);

    const onDateStartChange = (e) => {
        let date = e.value
        setDateStart(date)
        setValidateChange(true)
    }

    // calendar de fecha fin vigencia

    const [dateEnd, setDateEnd] = useState(null);

    const onDateEndChange = (e) => {
        let date = e.value
        setDateEnd(date)
        setValidateChange(true)
    }

    // calendat de fecha de firma de contrato

    const [dateContract, setDateContract] = useState(null)

    const onDateContractChange = (e) => {
        let date = e.value
        setDateContract(date)
    }

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Validar si hubo algun cambio 

    var changes = false
    if (validateChange === true) {
        changes = true
    }

    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        setSelectedClient({
            id: clientProp.id,
            name: clientProp.razonsocial
        })
        setInputContract({
            contract: contractProp
        })
        setInputObjectContract({
            objectContract: objectContractProp
        })

        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;

        const dateStartChange = new Date(dateStartProp)
        const dateEndChange = new Date(dateEndProp)
        const dateContractChange = new Date(dateContractProp)

        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
            setDateEnd(SumarDias(dateEndChange, +1))
            setDateContract(SumarDias(dateContractChange, +1))
        } else {
            setDateStart(dateStartChange)
            setDateEnd(dateEndChange)
            setDateContract(dateContractChange)
        }
    }, [clientProp, contractProp, objectContractProp, dateStartProp, dateEndProp, dateContractProp])

    //Almacenamos los valores para enviarlos por props

    const objectContract = inputObjectContract.objectContract
    const contract = inputContract.contract
    const idClient = selectedClient.id

    const resetData = () => {
        setSelectedClient({
            id: clientProp.id,
            name: clientProp.razonsocial
        })
        setInputContract({
            contract: contractProp
        })
        setInputObjectContract({
            objectContract: objectContractProp
        })

        var d = new Date()
        var gmtHours = -d.getTimezoneOffset() / 60;

        const dateStartChange = new Date(dateStartProp)
        const dateEndChange = new Date(dateEndProp)
        const dateContractChange = new Date(dateContractProp)

        if (gmtHours < 0) {
            setDateStart(SumarDias(dateStartChange, +1))
            setDateEnd(SumarDias(dateEndChange, +1))
            setDateContract(SumarDias(dateContractChange, +1))
        } else {
            setDateStart(dateStartChange)
            setDateEnd(dateEndChange)
            setDateContract(dateContractChange)
        }
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Back = (name) => {
            setRunRenderProp(runRenderProp + 1)
            setDisplayBasic(false)
            resetData()
            dialogFuncMap[`${name}`](false);
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }


    const renderFooter = (name) => {
        return (
            <div>
                {changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {
                    updatePermission ?
                        <ConfirmBtn
                            tittle="Confirmar Actualización"
                            menssage="¿Estás seguro de guardar esta información?"
                            action="Update"
                            label="Actualizar"
                            icon="pi pi-pencil"
                            idProp={idProp}
                            contractProp={contract}
                            objectContractProp={objectContract}
                            dateStartProp={CambiarFormatoFecha(dateStart)}
                            dateEndProp={CambiarFormatoFecha(dateEnd)}
                            dateContractProp={CambiarFormatoFecha(dateContract)}
                            idClientProp={idClient}
                            createdByProp={createdByProp}
                            creationDateProp={creationDateProp}
                            statusProp={statusProp}
                            setRunRenderProp={setRunRenderProp}
                            runRenderProp={runRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            setValidateChangeProp={setValidateChange}
                            updateDetailProp={false}
                        />
                        :
                        ""
                }
            </div>
        );
    }

    var contractError = false
    var contractMessage = ''

    if (contract !== '') {
        if (contract.length < 10) {
            contractError = true
            contractMessage = 'La descripción del contrato es demasiado corta'
        } else if (contract.length > 100) {
            contractError = true
            contractMessage = 'La descripción del contrato es demasiado larga'
        }
    }

    var objectContractError = false
    var objectContractMessage = ''

    if (objectContract !== '') {
        if (objectContract.length < 10) {
            objectContractError = true
            objectContractMessage = 'La descripción del objeto del contrato es demasiado corta'
        } else if (objectContract.length > 300) {
            objectContractError = true
            objectContractMessage = 'La descripción del objeto del contrato es demasiado larga'
        }
    }

    var dateStartError = false
    var dateStartMessage = ''

    if (dateStart !== null && dateEnd !== null) {
        if (dateStart > dateEnd) {
            dateStartError = true
            dateStartMessage = 'La fecha inicio vigencia no puede ser mayor a la fecha fin de vigencia'
        }
    }

    var dateContractError = false
    var dateContractMessage = ''

    if (dateContract !== null && dateEnd !== null) {
        if (dateContract >= dateEnd) {
            dateContractError = true
            dateContractMessage = 'La fecha de firma del contrato no puede ser mayor a la fecha fin de vigencia'
        }
    }

    // validación para crear

    var updatePermission = false

    if (contract !== '' && contractError === false && objectContract !== '' && objectContractError === false && dateStartError === false && dateContractError === false
        && selectedClient.id !== '' && dateContract !== null && dateStart !== null && dateEnd !== null) {
        updatePermission = true
    }

    return (
        <>
            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar contrato" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">
                    <form style={{ display: 'contents' }} >
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Cliente<span className="require">*</span></label>
                            <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                className="p-column-filter " emptyMessage="No hay resultados" name="Client" optionLabel="name" filter
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Contrato<span className="require">*</span></label>
                            <InputText id="name" type="text" name="contract" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onContractChange}
                                placeholder="Ingrese el contrato" value={contract}
                                className={contractError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{contractError ? contractMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Objeto del contrato<span className="require">*</span></label>
                            <InputText id="name" type="text" name="objectContract" keyfilter={/^[^<>*!=°":;_|(#$%&)+-/?¡¿'{}]+$/} onChange={onObjectContractChange}
                                placeholder="Ingrese el objeto del contrato" value={objectContract}
                                className={objectContractError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{objectContractError ? objectContractMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha inicio vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateStart} onChange={onDateStartChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateStartError ? "p-invalid" : ""} />
                            <p className="mensajeError">{dateStartError ? dateStartMessage : ""}</p>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha fin vigencia<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateEnd} onChange={onDateEndChange} dateFormat="dd-mm-yy" locale="es"
                                className={""}
                            />
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label className="labels" >Fecha firma contrato<span className="require">*</span></label>
                            <Calendar placeholder="Seleccione una fecha" value={dateContract} onChange={onDateContractChange} dateFormat="dd-mm-yy" locale="es"
                                className={dateContractError ? "p-invalid" : ""}
                            />
                            <p className="mensajeError">{dateContractError ? dateContractMessage : ""}</p>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default BtnActualizarContrato