import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
import { Toast } from 'primereact/toast';
import ConfirmBtn from './ConfirmBtn';
import Cookies from 'universal-cookie'
import axios from 'axios';
const cookies = new Cookies()


const BtnCrearServicio = ({ individualMonthReportProp, runRenderProp, setRunRenderProp, clientsProp, serviceProp }) => {

    const [clientsUserSession, setClientsUserSession] = useState([]);

    const getServices = new Services();

    const toast = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);

    useEffect(() => {
        const source = axios.CancelToken.source()
        if (displayBasic === true) {
            getServices.getProyectosUsuarios().then(data => {
                if (data !== undefined) {
                    var nameClients = []
                    data.forEach(element => {
                        var clients = {}
                        clients.id = element.cliente.id
                        clients.name = element.cliente.nombre
                        nameClients.push(clients)
                    })
                    const clientsTable = {}
                    const uniqueClients = nameClients.filter(function (object) {
                        return clientsTable.hasOwnProperty(object.id) ? false : (clientsTable[object.id] = true)
                    })

                    if (individualMonthReportProp === true) {
                        var newUniqueClients = uniqueClients.filter(function (object) {
                            var idClient = object.id
                            return parseInt(idClient) === clientsProp.id
                        })
                        setClientsUserSession(newUniqueClients)
                    } else {
                        setClientsUserSession(uniqueClients)
                    }
                }
            });

            if (individualMonthReportProp === true) {
                setSelectedClient({
                    name: clientsProp.client,
                    id: clientsProp.id
                })
            }

        }
        return () => {
            if (source) {
                getServices.cancelRequest()
            }
        }
    }, [clientsProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps


    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const [validateChange, setValidateChange] = useState(false)

    //Obtener datos del select tipo de clientes

    const [selectedClient, setSelectedClient] = useState({
        name: '',
        id: ''
    })

    const clients = clientsUserSession.map(item => {
        const av = { name: item.name, id: item.id }
        return av
    })

    const onClientChange = (e) => {
        setSelectedClient(e.value);
        setValidateChange(true)
    }

    //Obtener datos del input servicio

    const [inputService, setInputService] = useState({
        service: ''
    })

    const onServiceChange = (event) => {

        setInputService({
            ...inputService,
            [event.target.name]: event.target.value
        })
        setValidateChange(true)
    }

    //Validar si hubo algun cambio 

    var Changes = false
    if (validateChange === true) {
        Changes = true
    }

    //Almacenamos los valores para enviarlos por props

    var idClient = selectedClient.id
    var service = inputService.service

    // función para restablecer los datos del formulario

    const resetData = () => {
        if (individualMonthReportProp === true) {
            setSelectedClient({
                name: clientsProp.client,
                id: String(clientsProp.id)
            })
        }
        setInputService({
            service: ''
        })
        setValidateChange(false)
    }

    // Modal para el botón regresar

    const ModalBack = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);
            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            resetData()
        }

        const Back = (name) => {
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
            resetData()
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Back(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }

    const [showModal, setShowModal] = useState(false)

    const AddMoreServices = () => {
        const [displayBasic2, setDisplayBasic2] = useState(showModal);

        const dialogFuncMap = {
            'displayBasic2': setDisplayBasic2,
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
            setRunRenderProp(runRenderProp + 1)
            setDisplayBasic(false)
            setShowModal(false)
            resetData()
        }

        const AddMore = (name) => {
            dialogFuncMap[`${name}`](false);
            setShowModal(false);
            setRunRenderProp(runRenderProp + 1)
            resetData()
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} autoFocus className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => AddMore(name)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic2} modal style={{}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>Información registrada satisfactoriamente ¿Desea registrar otro servicio?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Changes ?
                    <ModalBack />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {registerPermission ?
                    <ConfirmBtn
                        tittle="Confirmar Registro"
                        menssage="¿Está seguro de Crear este servicio?"
                        action="Create"
                        label="Guardar"
                        icon="pi pi-plus-circle"
                        idClientProp={idClient}
                        serviceProp={service}
                        setShowModal={setShowModal}
                        statusProp={1}
                        runRenderProp={runRenderProp}
                        setRunRenderProp={setRunRenderProp}
                        /*  setDisplayBasicProp={setDisplayBasic} */
                        deleteSelectionClientProp={true}
                        setDeletedClientSelectionProp={setSelectedClient}
                    />
                    :
                    ""
                }

            </div>
        );
    }

    const usuario = cookies.get('usuario');

    // validaciones

    var serviceError = false
    var serviceMessage = ''

    if (service !== '') {
        if (service.length < 10) {
            serviceError = true
            serviceMessage = 'La descripción del servicio es demasiado corta'
        } else if (service.length > 100) {
            serviceError = true
            serviceMessage = 'La descripción del servicio es demasiado larga'
        } else if (service.length > 10 && service.length < 100) {
            if (serviceProp) {
                var serviceDuplicate = serviceProp.filter(obj => obj.cliente.id === parseInt(idClient) && obj.creadopor === usuario
                    && obj.descripcion.toLowerCase() === service.toLowerCase())
                if (serviceDuplicate.length !== 0) {
                    serviceError = true
                    serviceMessage = 'Ya has creado un servicio con el mismo cliente y la misma descripción'
                }
            }
        }
    }

    // validación para registrar

    var registerPermission = false

    if (service !== '' && serviceError === false && selectedClient.id !== '') {
        registerPermission = true
    }

    return (

        <div className="">
            <Toast ref={toast} />
            <div>
                <button className="btnAdd" onClick={() => {
                    onClick('displayBasic')
                    resetData()
                }}><span className="pi pi-plus Add"></span>Crear servicios</button>
                <AddMoreServices />
                <Dialog className="modal" header="Crear servicios" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                    <div className="p-fluid p-formgrid p-grid">
                        <form style={{ display: 'contents' }} >
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Cliente<span className="require">*</span></label>
                                <Dropdown value={selectedClient} options={clients} onChange={onClientChange} placeholder="Seleccione un cliente"
                                    className="p-column-filter " emptyMessage="No hay resultados" name="client" optionLabel="name" filter
                                />
                            </div>
                            <div className="p-field p-col-12 p-md-12">
                                <label className="labels" >Servicio<span className="require">*</span></label>
                                <InputText type='text' value={service} onChange={onServiceChange} name="service" placeholder="Ingrese la descripción del servicio"
                                    className={serviceError ? "p-invalid" : ""}
                                />
                                <p className="mensajeError">{serviceError ? serviceMessage : ""}</p>
                            </div>
                        </form>
                    </div>
                </Dialog>
            </div>
        </div>
    )

}
export default BtnCrearServicio