import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import Services from '../../service/Services';
import BtnCrearUsuario from './BtnCrearUsuario'
import BtnActualizarUsuario from './BtnActualizarUsuario'
import VerClientesProyectos from './VerClientesProyectos'
import ConfirmBtnEliminar from './ConfirmBtnEliminar'
import '../../assets/css/DataTable.css';
import { UseMedia, EstandarFormatoFecha, SumarDias } from '../Funciones';
import { UpdateValidity } from '../ActualizacionVigencias';
import BtnUserHistory from './BtnUserHistory';
import Cookies from 'universal-cookie';
import axios from 'axios';
const cookies = new Cookies();
const ConsultarUsuario = ({ permisosProp }) => {
    const rolSession = cookies.get('code')
    const [servicio, setServicio] = useState([]);
    const [runRender, setRunRender] = useState(0)
    const [loading, setLoading] = useState(true)
    const [messageToast, setMessageToast] = useState('')

    const toast = useRef(null);
    const getServices = new Services();

    var d = new Date()
    var gmtHours = -d.getTimezoneOffset() / 60;
    const [redirect, setRedirect] = useState(false)
    const [valueUnique, setValueUnique] = useState('')
    useEffect(() => {
        const source = axios.CancelToken.source();
        getServices.getUsuarios().then(data => {
            if (data !== undefined) {

                var newData
                if (rolSession === 'Super Admin') {
                    newData = data.filter(item => {
                        return item
                    })
                } else {
                    newData = data.filter(item => {
                        return item.rol.nombre !== 'Super Admin'
                    })
                }

                const newArrData = newData.map(item => {

                    var estado
                    if (item.estado === 0) {
                        estado = "Inactivo"
                    } else if (item.estado === 1) {
                        estado = "Activo"
                    } else if (item.estado === 2) {
                        estado = "Bloqueado"
                    } else if (item.estado === 3) {
                        estado = "Eliminado"
                    }
                    var newDate
                    if (gmtHours < 0) {
                        newDate = SumarDias(new Date(item.fecIngreso), 1)
                    } else {
                        newDate = new Date(item.fecIngreso)
                    }
                    return {
                        apellidos: item.apellidos,
                        cargo: item.cargo,
                        correo: item.correo,
                        estado: estado,
                        idEstado: item.estado,
                        fecIngreso: EstandarFormatoFecha(newDate),
                        fechacreacion: item.fechacreacion,
                        sendFecIngreso: item.fecIngreso,
                        idUsuario: item.idUsuario,
                        nombre: item.nombres,
                        nrodocumento: item.nrodocumento,
                        proyectos: item.proyectos,
                        rol: item.rol,
                        tipodocumento: item.tipodocumento,
                        razonsocial: item.razonsocial,
                        tipousuario: item.tipousuario.descripcion,
                        tipobloqueo: item.tipobloqueo,
                        intentos: item.intentos,
                        ultimafecharegistro: item.ultimafecharegistro,
                        usuario: item.usuario,
                        causalbloqueo: item.comentariobloqueo,
                        cargos: item.cargos,
                        fechaactualizado: item.fechaactualizado,
                        historicoestados: item.historicoestado,
                        proveedor: item.proveedor,
                        equipoTrabajoLider: item.equipoTrabajoLider,
                        equipoTrabajoUsuario: item.equipoTrabajoUsuario,
                        creadopor: item.creadopor,
                        historicoHoras: item.historicohoras,
                        idtipousuario:item.tipousuario.id
                    }
                })

                const urlUpdateValidityUsers = process.env.REACT_APP_BASE_URL + '/v1/api/user/updateUser'
                data.forEach(x => {
                    x.proyectos.forEach(element => {
                        if (x.estado === 2 && x.tipobloqueo?.descripcion === 'Retiro' && element.estado !== 0) {
                            const json = JSON.stringify(
                                {
                                    ...x,
                                    proyectos: [{
                                        estado: 0,
                                        fechacreacion: element.fechacreacion,
                                        fechafinvigencia: x.fechaactualizado,
                                        fechainiciovigencia: element.fechainiciovigencia,
                                        id: element.id,
                                        proyecto: {
                                            id: element.proyecto.id
                                        }
                                    }]
                                }
                            )
                            setLoading(true)
                            UpdateValidity(urlUpdateValidityUsers, json).then(data => {
                                setMessageToast(data.message)
                                setLoading(data.loading)
                                setRunRender(runRender + 1)
                            })
                        }
                    })
                })
                setLoading(false)
                setServicio(newArrData)
            }
        });
        if (messageToast !== '') {
            toast.current.show({ severity: 'success', summary: messageToast, detail: '', life: 3000 });
        }
        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [runRender]); // eslint-disable-line react-hooks/exhaustive-deps

    var permisoUserCreate
    if (permisosProp[0].permisos[0] === "Crear") {
        permisoUserCreate = true
    } else {
        permisoUserCreate = false
    }
    var permisoUserUpdate
    if (permisosProp[0].permisos[1] === "Editar") {
        permisoUserUpdate = true
    } else {
        permisoUserUpdate = false
    }
    var permisoUserDelete
    if (permisosProp[0].permisos[2] === "Eliminar") {
        permisoUserDelete = true
    } else {
        permisoUserDelete = false
    }
    const Acciones = (rowData) => {

        return (
            <div >
                {permisoUserUpdate ?
                    <BtnActualizarUsuario
                        tipoUsuarioProp={rowData.tipousuario}
                        numeroDocumentoProp={rowData.nrodocumento}
                        tipoDocumentoProp={rowData.tipodocumento}
                        nombreProp={rowData.nombre}
                        apellidoProp={rowData.apellidos}
                        cargoProp={rowData.cargo.cargo}
                        idCargoProp={rowData.cargo.id}
                        usuarioProp={rowData.usuario}
                        rolProp={rowData.rol.nombre}
                        idRolProp={rowData.rol.id}
                        estadoProp={rowData.estado}
                        idEstadoProp={rowData.idEstado}
                        contraseñaProp={rowData.nrodocumento}
                        proyectosProp={rowData.proyectos}
                        fechaingresoProp={rowData.sendFecIngreso}
                        creadoporProp={rowData.creadopor}
                        fechaCreacionProp={rowData.fechacreacion}
                        fechaactualizadoProp={rowData.fechaactualizado}
                        tipoBloqueoProp={rowData.tipobloqueo?.descripcion}
                        intentosProp={rowData.intentos}
                        idTipoBloqueoProp={rowData.tipobloqueo?.id}
                        editProp={rowData.tipobloqueo?.eseditable}
                        lastDateRegisterProp={rowData.ultimafecharegistro}
                        supplierProp={rowData.proveedor}
                        dataUserProp={[rowData]}
                        setRedirectProp={setRedirect}
                        setValueUniqueProp={setValueUnique}
                        dataCompletedProp={rowData}
                        historicoHorasProp={rowData.historicoHoras}
                        permisosProp={rowData.rol.permisos}
                        idTipoUsuarioProp={rowData.idtipousuario}
                    />
                    :
                    ""
                }
                <VerClientesProyectos
                    usuarioProp={rowData.usuario}
                    proyectosProp={rowData?.proyectos}
                    runRender={runRender}
                    setRunRender={setRunRender}
                    dataProp={rowData}
                    redirectProp={redirect}
                    valueUniqueProp={valueUnique}
                    setRedirectProp={setRedirect}
                    estadoProp={rowData.idEstado}
                />
                {permisoUserDelete ?
                    <ConfirmBtnEliminar
                        usuarioProp={rowData.usuario}
                        tooltipProp="Eliminar"
                        dataUserProp={[rowData]}
                        proyectosProp={rowData.proyectos}
                        isDeleteProjectUsersProp={false}
                    />
                    :
                    ""
                }
                <BtnUserHistory
                    positionHistoryProp={rowData.cargos}
                    statusHistoryProp={rowData.historicoestados}
                    dataCompletedProp={rowData}
                    runRenderProp={runRender}
                    setRunRenderProp={setRunRender}
                    historicoHorasProp={rowData.historicoHoras}
                />
            </div>
        );
    }

    const dt = useRef(null);

    const [globalFilter, setGlobalFilter] = useState(null);

    const handleChange = (e) => {
        e.preventDefault();
    };

    const header = (
        <div className="headerTable">
            {permisoUserCreate ?
                <>
                    <BtnCrearUsuario
                        dataUsersProp={servicio}
                    />
                </>
                :
                ""
            }
            {loading && <p>Cargando información...</p>}
            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-column-filter" keyfilter={/^[^<>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/}
                        onCut={handleChange} onCopy={handleChange} onPaste={handleChange}
                    />
                </span>
            </div>
        </div>
    );


    let small = UseMedia("(max-width: 760px)");

    const right = 'right'
    const left = 'left'
    const center = 'center'

    var permisosAcciones
    if (permisoUserUpdate === false && permisoUserDelete === false) {
        permisosAcciones = false
    } else {
        permisosAcciones = true
    }


    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable ref={dt} value={servicio} loading={loading} className="p-datatable-responsive-demo" paginator rows={10} header={header} globalFilter={globalFilter}
                emptyMessage="Colaborador no registrado en el sistema" responsiveLayout={small ? "stack" : "scroll"} breakpoint="760px">
                <Column field="tipodocumento" sortable /* headerStyle={{ width: '170px' }} */ header="Tipo Documento" />
                <Column field="nrodocumento" sortable headerStyle={{ textAlign: small ? left : center }} header="Documento" bodyStyle={{ textAlign: small ? left : center }} />
                <Column field="nombre" sortable header="Nombre" />
                <Column field="apellidos" sortable header="Apellido" />
                <Column field="cargo.cargo" sortable header="Cargo" />
                <Column field="sendFecIngreso" sortable header="Fecha Ingreso" />
                <Column field="estado" sortable header="Estado" />
                <Column field="usuario" sortable style={{ minWidth: '14rem' }} header="Usuario" />
                <Column field="rol.nombre" sortable style={{ minWidth: '14rem' }} header="Rol" />
                <Column field="tipousuario" sortable style={{ minWidth: '14rem' }} header="Tipo Usuario" />
                {permisosAcciones ?
                    <Column header="Acciones" style={{ minWidth: '13rem' }} headerStyle={{ textAlign: 'right', overflow: 'hidden' }} className="Acciones" bodyStyle={{ textAlign: small ? left : right }} body={Acciones} />
                    :
                    <Column />
                }

            </DataTable>
        </div>
    );
}

export default ConsultarUsuario