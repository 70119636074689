import React, { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import '../../assets/css/DialogDemo.css';
import '../../assets/css/Forms.css'
import { CambiarFormatoFecha } from '../Funciones';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ConfirmBtn = ({ icono, clase, titulo, mensaje, mensajeAlerta, accion, label, fechaProp, clienteProp, idClienteProp, proyectoProp,
    idProyectoProp, tipoActividadProp, idTipoActividadProp, ticketProp, areaProp, idAreaProp, sistemaProp,
    idSistemaProp, actividadProp, horasProp, idColaboradorProp, idActividadProp, idActividadesProp, usuarioProp, fechaCorreoProp, coordinadoConProp, creadoPorProp, fechaCreacionProp,
    esAdicionalProp, horasHoyProp, indicadorProp, tooltipProp, disabledProp, actividadSeleccionadaProp, jsonProp, extenderVacacionesProp, isTotalProp,
    dataProp, notasProp, idProveedorProp, horasMaxUsuarioProp, idObjetivoProp, idLogroProp,
    idEntregableProp, BtnAsociarActividadesProp, allDataProp, runRenderProp, setRunRenderProp, setDisplayBasicProp, setSelectedActivitiesProp }) => {

    const toast = useRef(null);

    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [setPosition] = useState('center');
    const usuario = cookies.get('usuario');

    const dialogFuncMap = {
        'displayConfirmation': setDisplayConfirmation,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        setSelectedDate(null)
        setShowErrorDataDuplicate(false)
    }

    const [disabled, setDisabled] = useState(false)
    /* console.log(dataProp) */
    const servicio = () => {
        if (accion === "Registrar") {
            dataProp.forEach(element => {
                RegistrarActividad(element)
            })
            toast.current.show({ severity: 'success', summary: 'Registro satisfatorio', detail: 'Registro satisfactorio', life: 3000 });
        } else if (accion === "Duplicar") {
            DuplicarActividad()
            setDisabled(true)
        } else if (accion === "Actualizar") {
            ActualizarActividad()
            setDisabled(true)
        } else if (accion === "Eliminar") {
            setDisabled(true)
            EliminarActividad()
        } else if (accion === "Novedad") {
            setDisabled(true)
            RegistrarNovedad()
        } else if (accion === "ActualizarNovedad") {
            setDisabled(true)
            ActualizarNovedad()
        }
    }

    const indicador = indicadorProp

    const urlRegistrar = process.env.REACT_APP_BASE_URL + '/v1/api/activity/registerActivity'
    const RegistrarActividad = async (json) => {
        var newJson = JSON.stringify({
            actualizadopor: json.actualizadopor,
            aplicacion: json.aplicacion,
            areanegocio: json.areanegocio,
            cliente: json.cliente,
            colaborador: json.colaborador,
            coordinadocon: json.coordinadocon,
            creadopor: json.creadopor,
            esadicional: json.esadicional,
            estado: json.estado,
            fechaactualizado: json.fechaactualizado,
            fechacorreoelec: json.fechacorreoelec,
            fechacreacion: json.fechacreacion,
            idempresa: json.idempresa,
            notas: json.notas,
            proyecto: json.proyecto,
            tipoActividad: json.tipoActividad,
            descripcionactividad: json.descripcionactividad,
            fecha: json.fecha,
            numerohoras: json.numerohoras,
            ticket: json.ticket,
            idproveedor: idProveedorProp,
            idObjetivo: json.idObjetivo,
            idLogro: json.idLogro,
            idEntregable: json.idEntregable,
        })

        await axios.post(urlRegistrar, newJson,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {

                /* toast.current.show({ severity: 'success', summary: 'Registro ' + mensajeAlerta, detail: response.data.message, life: 3000 }); */
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const DuplicarActividad = async () => {
        const json = JSON.stringify(
            {
                actualizadopor: "",
                aplicacion: {
                    id: idSistemaProp
                },
                areanegocio: {
                    id: idAreaProp
                },
                cliente: {
                    id: idClienteProp
                },
                colaborador: {
                    id: idColaboradorProp
                },
                coordinadocon: coordinadoConProp,
                creadopor: usuarioProp,
                descripcionactividad: actividadProp,
                esadicional: esAdicionalProp,
                estado: 1,
                fecha: selectedDate,
                fechaactualizado: "",
                fechacorreoelec: fechaCorreoProp,
                fechacreacion: new Date(),
                idempresa: {
                    id: 1
                },
                numerohoras: horasProp,
                proyecto: {
                    id: idProyectoProp
                },
                tipoActividad: {
                    id: idTipoActividadProp
                },
                ticket: ticketProp
            }
        )
        await axios.post(urlRegistrar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro ' + mensajeAlerta, detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }

    const [selectedDate, setSelectedDate] = useState(null);

    const onChangeDate = (e) => {
        setSelectedDate(e.value)
        validarCantidadHoras(e.value)
    }
    const [showErrorDataDuplicate, setShowErrorDataDuplicate] = useState(false);
    const [menssageDataDuplicate, setMenssageDataDuplicate] = useState('');

    const validarCantidadHoras = async (date) => {
        if (date !== null) {
            var fechaConvertida = CambiarFormatoFecha(date)
            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const json = JSON.stringify({
                esadicional: "1",
                fecFinal: fechaConvertida,
                fecInicial: fechaConvertida,
                usuario: usuario
            })
            await axios.post(url, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    },
                    /* cancelToken: source.token */
                })
                .then(response => {
                    if (response !== undefined) {

                        var dataDuplicate = []
                        var newArrayDataRegistered = response.data.map(item => {
                            return {
                                cliente: {
                                    id: item.cliente.id
                                },
                                proyecto: {
                                    id: item.proyecto.id
                                },
                                tipoActividad: {
                                    id: item.tipoActividad.id
                                },
                                areanegocio: {
                                    id: item.areanegocio.id
                                },
                                aplicacion: {
                                    id: item.aplicacion.id
                                },
                                fecha: item.fecha,
                                descripcionactividad: item.descripcionactividad.toLowerCase(),
                                ticket: item.ticket,
                            }
                        })

                        let comparatorObject = [{
                            cliente: {
                                id: idClienteProp
                            },
                            proyecto: {
                                id: idProyectoProp
                            },
                            tipoActividad: {
                                id: idTipoActividadProp
                            },
                            areanegocio: {
                                id: idAreaProp
                            },
                            aplicacion: {
                                id: idSistemaProp
                            },
                            fecha: fechaConvertida,
                            descripcionactividad: actividadProp.toLowerCase(),
                            ticket: ticketProp
                        }]

                        newArrayDataRegistered.forEach(x => {
                            comparatorObject.forEach(y => {
                                if ((JSON.stringify(x) === JSON.stringify(y) === true)) {
                                    dataDuplicate.push(y)
                                }
                            })
                        })
                        if (dataDuplicate.length !== 0) {
                            setShowErrorDataDuplicate(true)
                            setMenssageDataDuplicate('Los datos registrados son iguales a una actividad ya registrada')
                        } else {
                            setShowErrorDataDuplicate(false)
                            setMenssageDataDuplicate('')
                        }
                    }
                })
                .catch(function (error) {
                    //console.log(error.response)
                })
        }
    }

    /* console.log(showErrorDataDuplicate); */
    const idActividad = idActividadProp

    const urlActualizar = process.env.REACT_APP_BASE_URL + '/v1/api/activity/update'
    const ActualizarActividad = async () => {

        const json = JSON.stringify(
            BtnAsociarActividadesProp === false ?
                {
                    actividad: {
                        actualizadopor: usuarioProp,
                        aplicacion: {
                            id: idSistemaProp
                        },
                        areanegocio: {
                            id: idAreaProp
                        },
                        cliente: {
                            id: idClienteProp
                        },
                        colaborador: {
                            id: idColaboradorProp
                        },
                        coordinadocon: coordinadoConProp,
                        creadopor: creadoPorProp,
                        descripcionactividad: actividadProp,
                        esadicional: esAdicionalProp,
                        estado: 1,
                        fecha: fechaProp,
                        fechaactualizado: new Date(),
                        fechacorreoelec: fechaCorreoProp,
                        fechacreacion: fechaCreacionProp,
                        id: idActividad,
                        idempresa: {
                            id: 1
                        },
                        numerohoras: horasProp,
                        proyecto: {
                            id: idProyectoProp
                        },
                        tipoActividad: {
                            id: idTipoActividadProp
                        },
                        ticket: ticketProp,
                        notas: notasProp,
                        idproveedor: parseInt(idProveedorProp),
                        idObjetivo: idObjetivoProp,
                        idLogro: idLogroProp,
                        idEntregable: idEntregableProp,
                    }
                }
                :

                {
                    actividades: allDataProp
                }

        )
        /* console.log(json) */

        await axios.post(urlActualizar, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Actualización Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                if (BtnAsociarActividadesProp === true) {
                    setRunRenderProp(runRenderProp + 1)
                    setDisplayBasicProp(false)
                    setSelectedActivitiesProp([])
                } else {
                    window.location.href = "/registroActividades/consultarActividades/" + indicador
                }

            })
            .catch(function (error) {
               /*  console.log(error.response) */
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })
    }


    var textIds = ""
    if (idActividadesProp !== undefined) {
        idActividadesProp.forEach(element => {
            textIds = textIds + "activitys=" + element + "&"

        });

    }



    const urlEliminarCheck = process.env.REACT_APP_BASE_URL + '/v1/api/activity/deleteList?' + textIds
    const EliminarActividad = async () => {

        await axios.post(urlEliminarCheck, idActividadesProp,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Eliminación Satisfactoria', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }


    var a = parseInt(horasProp)
    var b = parseInt(horasHoyProp)
    var validarHorasHoy = a + b
    var permisosDuplicar = true

    if (validarHorasHoy > 24) {
        permisosDuplicar = false
    }


    var lastIdNew

    /* console.log(isTotalProp) */

    const RegistrarDetalleNovedad = async (horasMaxUsuario) => {
        const urlDetalleRegistrarNovedad = `${process.env.REACT_APP_BASE_URL}/v1/api/parameter/detailNews/registryList/${extenderVacacionesProp}/${horasMaxUsuario}`

        if (lastIdNew.length !== 0) {
            lastIdNew.sort(function (a, b) {
                var textA = a.id;
                var textB = b.id;
                return textB - textA
            })
        }

        const json = jsonProp.proyecto.map(item => {
            return {
                id: '',
                novedad: {
                    id: lastIdNew[0].id
                },
                porcentaje: item.porcentaje,
                proyecto: {
                    id: item.id
                }
            }
        })

        /* console.log(json) */
        await axios.post(urlDetalleRegistrarNovedad, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }

    const ultimoIdNovedad = async (horasMaxUsuario) => {
        const urlGetNovedadesColaborador = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/news/getByColaboratorId/' + jsonProp.idColaborador
        await axios.get(urlGetNovedadesColaborador,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                //console.log(response.data)
                lastIdNew = response.data
                RegistrarDetalleNovedad(horasMaxUsuario)

            })
            .catch(function (error) {
                //console.log(error.response)
            })

    }

    const RegistrarNovedad = async () => {
        const urlRegistrarNovedad = process.env.REACT_APP_BASE_URL + '/v1/api/parameter/news'

        const fechaInicio = CambiarFormatoFecha(jsonProp.fechaInicio)
        const fechaFin = CambiarFormatoFecha(jsonProp.fechaFin)

        const json = JSON.stringify(
            {
                colaborador: {
                    id: jsonProp.idColaborador
                },
                descripcion: jsonProp.actividad,
                estado: 1,
                fecCreacion: '',
                fecFin: fechaFin,
                fecInicio: fechaInicio,
                id: '',
                isTotal: !isTotalProp,
                tipoNovedad: {
                    id: jsonProp.idTipoNovedad
                },
                usuarioCreacion: jsonProp.usuario,
                aplicacion: {
                    id: jsonProp.aplicacion
                },
                areanegocio: {
                    id: jsonProp.areanegocio
                },
                idproveedor: jsonProp.idproveedor,
                numerohoras: jsonProp.numerohoras
            }
        )
        /* console.log(json) */
        await axios.post(urlRegistrarNovedad, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                ultimoIdNovedad(horasMaxUsuarioProp)

            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }


    const ActualizarNovedad = async () => {
        const urlRegistrarNovedad = `${process.env.REACT_APP_BASE_URL}/v1/api/parameter/news/update/${extenderVacacionesProp}/${horasMaxUsuarioProp}`

        const detalle = jsonProp.proyecto.map(item => {
            return {
                id: item.idDetalleNovedad,
                novedad: {
                    id: jsonProp.idNovedad
                },
                porcentaje: item.porcentaje,
                proyecto: {
                    id: item.idProyecto
                }
            }
        })


        const fechaInicio = CambiarFormatoFecha(jsonProp.fechaInicio)
        const fechaFin = CambiarFormatoFecha(jsonProp.fechaFin)

        const json = JSON.stringify(
            {
                colaborador: {
                    id: jsonProp.idColaborador
                },
                descripcion: jsonProp.actividad,
                detalle: detalle,
                estado: 1,
                fecCreacion: jsonProp.fecCreacion,
                fecFin: fechaFin,
                fecInicio: fechaInicio,
                id: jsonProp.idNovedad,
                isTotal: isTotalProp,
                tipoNovedad: {
                    id: jsonProp.idTipoNovedad
                },
                usuarioCreacion: jsonProp.usuarioCreacion,
                aplicacion: {
                    id: jsonProp.aplicacion
                },
                areanegocio: {
                    id: jsonProp.areanegocio
                },
                idproveedor: jsonProp.idproveedor,
                numerohoras: jsonProp.numerohoras
            }
        )
        /* console.log(json) */
        await axios.post(urlRegistrarNovedad, json,
            {
                headers:
                {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Registro satisfactorio', detail: response.data.message, life: 3000 });
                //console.log(response.data)
                window.location.href = "/registroActividades/consultarActividades/" + indicador
            })
            .catch(function (error) {
                //console.log(error.response)
                toast.current.show({ severity: 'error', summary: '¡Algo salio mal!', detail: error.response.data.mensaje, life: 6000 });
            })

    }


    const renderFooter = (name) => {
        return (
            <>
                {permisosDuplicar ?
                    <div >
                        <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                        <Button label="Si" icon="pi pi-check" onClick={() => servicio()} autoFocus disabled={disabled || (accion === "Duplicar" && !selectedDate) || showErrorDataDuplicate === true} />
                    </div>
                    :
                    <div>
                        <Button label="Entendido" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />

                    </div>


                }

            </>
        );
    }

    var style = {
        display: 'flex',
        justifyContent: 'center'
    }

    return (
        <>
            <Toast ref={toast} />
            <Button label={label} icon={icono} onClick={() => onClick('displayConfirmation')} className={clase}
                tooltip={tooltipProp} tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} disabled={disabledProp}
            />
            <Dialog className="dialog-demo" header={permisosDuplicar ? titulo : "Advertencia"} visible={displayConfirmation} modalbreakpoints={{ '960px': '75vw' }} style={style}
                footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                <div className="confirmation-content">
                    <span>{permisosDuplicar ? mensaje : "No puedes Duplicar esta actividad, porque superaría el limite de horas permitido "}</span>

                </div>
                {accion === "Duplicar" && (
                    <div className="p-field p-col-12 p-md-12"  >
                        <label className="labels" >Seleccionar fecha<span className="require">*</span></label>
                        <Calendar value={selectedDate} onChange={(e) => onChangeDate(e)} dateFormat="yy-mm-dd" />
                    </div>
                )}
                <div className="p-field p-col-12 p-md-12" >
                    {showErrorDataDuplicate === true ?
                        <Message severity="error" text={menssageDataDuplicate} />
                        :
                        ""
                    }
                </div>
            </Dialog>

        </>
    )
}
export default ConfirmBtn