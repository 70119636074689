import React, { useRef,useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
/* import { SlideMenu } from 'primereact/slidemenu'; */
import { PanelMenu } from 'primereact/panelmenu';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';

import IMG from '../../../assets/images/usuario.png'
/* import { FaBars } from 'react-icons/fa'; */

import Cookies from 'universal-cookie';
import Services from '../../../service/Services';

const cookies = new Cookies();

function MobileSidebar({ acceso, noShowProp }) {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    const [showMessage, setShowMessage] = useState(false);
    const toast = useRef(null);

    const usuario = cookies.get('nombre')
    const rol = cookies.get('code')

    let history = useHistory();

    var newItems = []

    if (acceso[13].permisos[0] === "DB1" && acceso[13].permisos[1] === "DB2" && acceso[13].permisos[2] === "DB3") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [

                    {
                        label: 'Sobrecarga de trabajo y proyectos',
                        icon: 'pi pi-fw pi-chart-bar',
                        command: () => {
                            history.push("/registroActividades/SobrecargaTrabajoProyecto");
                        }
                    },
                    {
                        label: 'Detalle tipos de actividad',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividad");
                        }
                    },
                    {
                        label: 'Tipos de actividad - Funcionario',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionario");
                        }
                    },
                ]
            },
        )
    } else if (acceso[13].permisos[0] === "DB1" && acceso[13].permisos[1] === "DB2" && acceso[13].permisos[2] === "") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [

                    {
                        label: 'Sobrecarga de trabajo y proyectos',
                        icon: 'pi pi-fw pi-chart-bar',
                        command: () => {
                            history.push("/registroActividades/SobrecargaTrabajoProyecto");
                        }
                    },
                    {
                        label: 'Detalle tipos de actividad',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividad");
                        }
                    },
                ]
            },
        )
    } else if (acceso[13].permisos[0] === "DB1" && acceso[13].permisos[1] === "" && acceso[13].permisos[2] === "DB3") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [

                    {
                        label: 'Sobrecarga de trabajo y proyectos',
                        icon: 'pi pi-fw pi-chart-bar',
                        command: () => {
                            history.push("/registroActividades/SobrecargaTrabajoProyecto");
                        }
                    },
                    {
                        label: 'Tipos de actividad - Funcionario',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionario");
                        }
                    },
                ]
            },
        )
    } else if (acceso[13].permisos[0] === "" && acceso[13].permisos[1] === "DB2" && acceso[13].permisos[2] === "DB3") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Detalle tipos de actividad',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividad");
                        }
                    },
                    {
                        label: 'Tipos de actividad - Funcionario',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionario");
                        }
                    },

                ]
            },
        )
        console.log(acceso[13])
    } else if (acceso[13].permisos[0] === "DB1" && acceso[13].permisos[1] === "" && acceso[13].permisos[2] === "") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Sobrecarga de trabajo y proyectos',
                        icon: 'pi pi-fw pi-chart-bar',
                        command: () => {
                            history.push("/registroActividades/SobrecargaTrabajoProyecto");
                        }
                    },
                ]
            },
        )
    } else if (acceso[13].permisos[0] === "" && acceso[13].permisos[1] === "DB2" && acceso[13].permisos[2] === "") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Detalle tipos de actividad',
                        icon: 'pi pi-fw pi-chart-bar',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividad");
                        }
                    },
                ]
            },
        )
    } else if (acceso[13].permisos[0] === "" && acceso[13].permisos[1] === "" && acceso[13].permisos[2] === "DB3") {
        newItems.push(
            {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Tipos de actividad - Funcionario',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionario");
                        }
                    },
                ]
            },
        )
    }

    //Nuevo Dashboards que muestra filtro de proveedor
    if (acceso[28].permisos[0] === "" && acceso[28].permisos[1] === "NDB1" && acceso[28].permisos[2] === "NDB2") {
        newItems.push(
            {
                label: 'Dashboards - Proveedor',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Detalle tipos de actividad Proveedor',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividadProveedor");
                        }
                    },
                    {
                        label: 'Tipos de actividad - Funcionario Proveedor',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionarioProveedor");
                        }
                    },
                ]
            },
        )
    } else if (acceso[28].permisos[0] === "" && acceso[28].permisos[1] === "NDB1" && acceso[28].permisos[2] === "") {
        newItems.push(
            {
                label: 'Dashboards - Proveedor',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Detalle tipos de actividad Proveedor',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividadProveedor");
                        }
                    },
                ]
            },
        )
    } else if (acceso[28].permisos[0] === "" && acceso[28].permisos[1] === "" && acceso[28].permisos[2] === "NDB2") {
        newItems.push(
            {
                label: 'Dashboards - Proveedor',
                icon: 'pi pi-fw pi-home',
                items: [

                    {
                        label: 'Tipos de actividad - Funcionario Proveedor',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionarioProveedor");
                        }
                    },
                ]
            },
        )
    } else if (acceso[28].permisos[0] === "" && acceso[28].permisos[1] === "NDB1" && acceso[28].permisos[2] === "NDB2") {
        newItems.push(
            {
                label: 'Dashboards - Proveedor',
                icon: 'pi pi-fw pi-home',
                items: [
                    {
                        label: 'Detalle tipos de actividad Proveedor',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/detalleTipoActividadProveedor");
                        }
                    },
                    {
                        label: 'Tipos de actividad - Funcionario Proveedor',
                        icon: 'pi pi-fw pi-chart-pie',
                        command: () => {
                            history.push("/registroActividades/tipoActividadFuncionarioProveedor");
                        }
                    },

                ]
            },
        )
    }
    const [linkPowerBi, setLinkPowerBi] = useState('');
    const [linkPowerBiSupplier, setLinkPowerBiSupplier] = useState('');

    useEffect(() => {
        if (acceso[22].permisos[1] === 'powerBiClient' && noShowProp !== true) {
            const getService = new Services();
            getService.getProyectosUsuarios().then(data => {
                var newData = data.filter(obj => obj.cliente.linkpowerbi !== null && obj.cliente.linkpowerbi !== "")
                if (newData.length !== 0) {
                    setLinkPowerBi(JSON.parse(newData[0].cliente.linkpowerbi)?.linkPowerBI);
                    setShowMessage(false);
                } else {
                    setLinkPowerBi('');
                    setShowMessage(true);
                }
            })
        }
        if (acceso[30].permisos[1] === 'powerBiSupplierClient' &&  noShowProp !== true) {
            const getService = new Services();
            getService.getProyectosUsuarios().then(data => {
                var newData = data.filter(obj => obj.cliente.linkpowerbi !== null && obj.cliente.linkpowerbi !== "")
                if (newData.length !== 0) {
                    setLinkPowerBiSupplier(JSON.parse(newData[0].cliente.linkpowerbi)?.linkPowerBISupplier);
                    setShowMessage(false)
                } else {
                    setLinkPowerBiSupplier('');
                    setShowMessage(true);
                }
            })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
   


    if (acceso[22].DBPowerBI === 'true' || acceso[22].permisos[1] === 'powerBiClient' || acceso[22].permisos[0] === 'plusPowerBi') {
        var itemsDBPBI = []
        if (acceso[22].DBPowerBI === 'true') {
            itemsDBPBI.push(
                {
                    label: 'Dashboard PowerBI',
                    icon: 'pi pi-fw pi-globe',                                       
                    url:process.env.REACT_APP_POWERBI_URL                     
                }
            )
        }
        if (acceso[22].permisos[1] === 'powerBiClient') {
            itemsDBPBI.push(
                {
                    label: 'Dashboard PowerBI de Cliente',
                    icon: 'pi pi-fw pi-globe',                   
                    url:linkPowerBi, 
                    command: () => {
                        if (showMessage === true) {
                            toast.current.show({ 
                                severity: 'info', 
                                summary: 'Dashboard PowerBI', 
                                detail: 'sin información para mostrar', 
                                life: 3000,
                            });
                        }
                    }   
                }
            )
        }
        if (acceso[22].permisos[0] === 'plusPowerBi') {
            itemsDBPBI.push(
                {
                    label: 'Dashboard PowerBI con Licencia',
                    icon: 'pi pi-fw pi-globe',                    
                    url:`https://app.powerbi.com/view?r=eyJrIjoiMmU0NmFlZjktMWY0My00MDRmLWFlYTEtNmIyNzIzZTM5ZDdjIiwidCI6IjA5NDUyM2MwLWI5NzQtNDE3NS05ZjdmLWZiZTJmMGQ3OGI4OSJ9&pageName=ReportSectiona77d83557d7f3ed35ed8`    
                }
            )
        }
        newItems.push(
            {
                label: 'Dashboard PowerBI',
                icon: 'pi pi-fw pi-microsoft',
                items: itemsDBPBI
            }
        )
    }
    if (acceso[30].DBPowerBISupplier === 'true' || acceso[30].permisos[0] === 'plusPowerBiSupplier' || acceso[30].permisos[1] === 'powerBiSupplierClient') {
        var itemsDBPBISupplier = []
        if (acceso[30].DBPowerBISupplier === 'true') {
            itemsDBPBISupplier.push(
                {
                    label: 'Dashboard PowerBI - Proveedor',
                    icon: 'pi pi-fw pi-globe',               
                    url:process.env.REACT_APP_POWERBI_SUPPLIER_URL
                }
            )
        }
        if (acceso[30].permisos[0] === 'plusPowerBiSupplier') {
            itemsDBPBISupplier.push(
                {
                    label: 'Dashboard PowerBI con Licencia - Proveedor',
                    icon: 'pi pi-fw pi-globe',
                    url:`https://app.powerbi.com/view?r=eyJrIjoiYTllZGRkNTgtNzdkOS00YTM3LWI2MjMtYTAwNGNjODlkMjNkIiwidCI6IjA5NDUyM2MwLWI5NzQtNDE3NS05ZjdmLWZiZTJmMGQ3OGI4OSJ9&pageName=ReportSectiona77d83557d7f3ed35ed8`     
                }
            )
        }
        if (acceso[30].permisos[1] === 'powerBiSupplierClient') {
            itemsDBPBISupplier.push(
                {
                    label: 'Dashboard PowerBI de Cliente - Proveedor',
                    icon: 'pi pi-fw pi-globe',
                    url:linkPowerBiSupplier,
                    command: () => {
                        if (showMessage === true) {
                            toast.current.show({ 
                                severity: 'info', 
                                summary: 'Dashboard PowerBI', 
                                detail: 'sin información para mostrar', 
                                life: 3000,
                            });
                        }
                    }  
                }
            )
        }

        newItems.push(
            {
                label: 'Dashboard PowerBI - Proveedor',
                icon: 'pi pi-fw pi-microsoft',
                items: itemsDBPBISupplier
            }
        )
    }

    acceso.forEach((item, index) => {
        var permisoConsultar
        if (item.consultar !== "false") {
            permisoConsultar = false
        } else {
            permisoConsultar = true
        }
        if (!item.consultarCalendario) {
            if (item.consultarCalendario === "false") {
                permisoConsultar = false
            }

        } else {
            permisoConsultar = true
        }
        if (!item.ProcesosCierre) {
            if (item.ProcesosCierre === "false") {
                permisoConsultar = false
            }

        } else {
            permisoConsultar = true
        }
        if (!item.DescargarZip) {
            if (item.DescargarZip === "false") {
                permisoConsultar = false
            }

        } else {
            permisoConsultar = true
        }
        if (!item.DashBoards) {
            if (item.DashBoards === "false") {
                permisoConsultar = false
            }

        } else {
            permisoConsultar = true
        }
        if (!item.DBPowerBI) {
            if (item.DBPowerBI === "false") {
                permisoConsultar = false
            }

        } else {
            permisoConsultar = true
        }
        if (!item.NuevoDashboards) {
            if (item.NuevoDashboards === "false") {
                permisoConsultar = false
            }
        } else {
            permisoConsultar = true
        }
        if (!item.DBPowerBISupplier) {
            if (item.DBPowerBISupplier === "false") {
                permisoConsultar = false
            }
        } else {
            permisoConsultar = true
        }

        var icon = ''

        if (item.menu === 'Usuarios') {
            icon = 'pi pi-fw pi-user'
        }
        if (item.menu === 'Actividades') {
            icon = 'pi pi-fw pi-chart-line'
        }
        if (item.menu === 'Exportar Datos') {
            icon = 'pi pi-fw pi-external-link'
        }
        if (item.menu === 'Clientes') {
            icon = 'pi pi-fw pi-users'
        }
        if (item.menu === 'Proyectos') {
            icon = 'pi pi-fw pi-folder'
        }
        if (item.menu === 'Aplicaciones') {
            icon = 'pi pi-fw pi-th-large'
        }
        if (item.menu === 'Áreas de Negocio') {
            icon = 'pi pi-fw pi-briefcase'
        }
        if (item.menu === 'Tipos de Actividad') {
            icon = 'pi pi-fw pi-sliders-v'
        }
        if (item.menu === 'Cargos') {
            icon = 'pi pi-fw pi-id-card'
        }
        if (item.menu === 'Roles') {
            icon = 'pi pi-fw pi-sitemap'
        }
        if (item.menu === 'Historico') {
            icon = 'pi pi-fw pi-history'
        }
        if (item.menu === 'Informe mensual individual') {
            icon = 'pi pi-fw pi-book'
        }
        if (item.menu === 'Personas interesadas') {
            icon = 'pi pi-fw pi-wallet'
        }
        if (item.menu === 'Objetivos') {
            icon = 'pi pi-fw pi-tags'
        }
        if (item.menu === 'Gestionar Contratos') {
            icon = 'pi pi-fw pi-file'
        }
        if (item.menu === 'Entregables') {
            icon = 'pi pi-fw pi-server'
        }
        if (item.menu === 'Servicios') {
            icon = 'pi pi-fw pi-paperclip'
        }
        if (item.menu === 'Gestión de Equipos de Trabajo') {
            icon = 'pi pi-fw pi-users'
        }
        if (item.menu === 'Tipos de Proyectos') {
            icon = 'pi pi-fw pi-box'
        }
        if (item.menu === 'Organigrama') {
            icon = 'pi pi-sitemap'
        }
        if (item.menu === 'Detalle de Pendientes') {
            icon = 'pi pi-info'
        }
        if (item.menu === 'Usuarios de clientes') {
            icon = 'pi pi-link'
        }
        if (item.menu === 'Proveedores') {
            icon = 'pi pi-truck'
        }
        if (item.menu === 'Exportar datos proveedor') {
            icon = 'pi pi-fw pi-external-link'
        }

        if (item.menu === 'Cargar Imagen Corporativa') {
            icon = 'pi pi-upload'
        }

        if (permisoConsultar === false) {
            newItems.push({

                label: item.menu,
                icon: icon,
                command: () => {
                    history.push(item.ruta);
                }
            })
        }

    })

    if (acceso[11].DescargarZip === "true") {
        newItems.push(
            {
                label: 'Descargar zip',
                icon: 'pi pi-fw pi-download',
                command: () => {
                    window.open(process.env.REACT_APP_ZIP_URL);
                }
            }

        )
    }


    if (acceso[10].consultarCalendario === "true") {
        if (acceso[10].permisos[0] === "Parametrizar") {
            newItems.push(
                {
                    label: 'Calendario',
                    icon: 'pi pi-fw pi-calendar',
                    items: [

                        {
                            label: 'Consultar',
                            icon: 'pi pi-fw pi-search',
                            command: () => {
                                history.push("/registroActividades/consultarCalendario");
                            }
                        },
                        {
                            label: 'Parametrizar',
                            icon: 'pi pi-fw pi-calendar-plus',
                            command: () => {
                                history.push("/registroActividades/parametrizarCalendario");
                            }
                        },

                    ]
                },
            )
        } else {
            newItems.push(
                {
                    label: 'Calendario',
                    icon: 'pi pi-fw pi-calendar',
                    items: [
                        {
                            label: 'Consultar',
                            icon: 'pi pi-fw pi-search',
                            command: () => {
                                history.push("/registroActividades/consultarCalendario");
                            }
                        },
                    ]
                },
            )
        }
    }

    if (acceso[12].permisos[1] === "CierreDefinitivo" && acceso[12].permisos[0] === "AbrirMes") {
        newItems.push(
            {
                label: 'Procesos Cierre',
                icon: 'pi pi-fw pi-info-circle',
                items: [
                    {
                        label: 'Cierre definitivo',
                        icon: 'pi pi-fw pi-lock',
                        command: () => {
                            history.push("/registroActividades/cerrarMes");
                        }
                    },
                    {
                        label: 'Abrir mes',
                        icon: 'pi pi-fw pi-unlock',
                        command: () => {
                            history.push("/registroActividades/abrirMes");
                        }
                    },
                ]
            },
        )
    } else if (acceso[12].permisos[0] === "" && acceso[12].permisos[1] === "AbrirMes") {
        newItems.push(
            {
                label: 'Procesos Cierre',
                icon: 'pi pi-fw pi-info-circle',
                items: [
                    {
                        label: 'Abrir mes',
                        icon: 'pi pi-fw pi-unlock',
                        command: () => {
                            history.push("/registroActividades/cerrarMes");
                        }
                    },
                ]
            },
        )
    } else if (acceso[12].permisos[0] === "CierreDefinitivo" && acceso[12].permisos[1] === "") {
        newItems.push(
            {
                label: 'Procesos Cierre',
                icon: 'pi pi-fw pi-info-circle',
                items: [
                    {
                        label: 'Cierre definitivo',
                        icon: 'pi pi-fw pi-lock',
                        command: () => {
                            history.push("/registroActividades/abrirMes");
                        }
                    },
                ]
            },
        )
    }

    return (
        <div>
            <div className="mobile_nav">
                <div className="nav_bar">
                    <img src={IMG} className="mobile_profile_image" alt="" />
                    <h4 className="profile_infoH4_Mobile">Usted ingresó como: {usuario}</h4>
                    <h4 className="profile_infoH4_Mobile">{rol}</h4>
                    <button className="BtnFaBars" onClick={showSidebar}><i className="fa fa-bars nav_btn"></i></button>
                </div>
                <div className={sidebar ? "mobile_nav_items active" : "mobile_nav_items "}>
                    {/* <SlideMenu model={newItems} viewportHeight={550} menuWidth={window.innerWidth} effectDuration={500} backLabel="Regresar"></SlideMenu> */}
                    <PanelMenu model={newItems} />
                </div>

            </div>
            <Toast ref={toast} />
        </div>
    )


}

export default MobileSidebar