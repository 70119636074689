import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import ConfirmBtn from './ConfirmBtn.js'
import Services from '../../service/Services';
import '../../assets/css/Forms.css'
import '../../assets/css/DialogDemo.css'
/* import { Link} from 'react-router-dom' */
import { CambiarFormatoFecha, SumarDias } from '../Funciones.js';
import Cookies from 'universal-cookie';
import ModalAdvertencia from '../ModalAdvertencia/ModalAdvertencia.js';
import axios from 'axios'
const cookies = new Cookies();


const BtnActualizarUsuario = ({ tipoUsuarioProp, numeroDocumentoProp, tipoDocumentoProp, nombreProp, apellidoProp, cargoProp, idCargoProp, usuarioProp,
    rolProp, idRolProp, estadoProp, idEstadoProp, contraseñaProp, proyectosProp, fechaingresoProp, creadoporProp, fechaCreacionProp, fechaactualizadoProp, tipoBloqueoProp,
    idTipoBloqueoProp, editProp, intentosProp, lastDateRegisterProp, supplierProp, dataUserProp,
    setRedirectProp, setValueUniqueProp, dataCompletedProp, historicoHorasProp, permisosProp, idTipoUsuarioProp }) => {

    const [servicioCargo, setServicioCargo] = useState([]);
    const [servicioRol, setServicioRol] = useState([]);
    const [servicioTipoDocumento, setServicioTipoDocumento] = useState([]);
    const [servicioCliente, setServicioCliente] = useState([]);
    const [servicioProyecto, setServicioProyecto] = useState([]);
    const [servicioTipoBloqueo, setServicioTipoBloqueo] = useState([]);
    const [servicioProveedor, setServicioProveedor] = useState([]);
    const [servicioTipoUsuario, setServicioTipoUsuario] = useState([]);
    const getServices = new Services();
    const [displayBasic, setDisplayBasic] = useState(false);
    const [changeHour, setChangeHour] = useState(false)
    const [showModal, setShowModal] = useState(false)


    useEffect(() => {
        let source; //Se crea una instancia para luego cancelar las peticiones
        if (displayBasic === true) {
            source = axios.CancelToken.source();
            getServices.getTiposDocumento().then(data => {
                if (data !== undefined) {
                    setServicioTipoDocumento(data)
                }
            });
            getServices.getCargos().then(data => {
                if (data !== undefined) {
                    setServicioCargo(data)
                }
            });
            getServices.getTipoUsuario().then(data => {
                if (data !== undefined) {
                    setServicioTipoUsuario(data)
                }
            })
            getServices.getRoles().then(data => {
                if (data !== undefined) {
                    setServicioRol(data)
                }
            });
            getServices.getClientes().then(data => {
                if (data !== undefined) {
                    setServicioCliente(data)
                }
            });
            getServices.getProyectos().then(data => {
                if (data !== undefined) {
                    setServicioProyecto(data)
                }
            });
            getServices.getTipoBloqueo().then(data => {
                if (data !== undefined) {
                    setServicioTipoBloqueo(data)
                }
            })
            getServices.getSuppliers().then(data => {
                if (data !== undefined) {
                    setServicioProveedor(data)
                }
            })
        }
        return () => {
            if (source !== undefined && source) {
                getServices.cancelRequest()
            }
        }
    }, [displayBasic]); // eslint-disable-line react-hooks/exhaustive-deps

    const [setPosition] = useState('center');

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,

    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    // Selects

    //Obtener datos del select tipo de usuario

    const [selectedTipoUser, setSelectedTipoUser] = useState({
        tipoUsuario: tipoUsuarioProp,
        id: idTipoUsuarioProp
    });
    const tipoUsers = servicioTipoUsuario.map(item => {
        const av = { tipoUsuario: item.descripcion, id: item.id }
        return av
    })

    const onTipoUserChange = (e) => {
        setSelectedTipoUser(e.value);
    }

    const [validarCambio, setValidarCambio] = useState(false)

    //Obtener datos del select tipo de documento

    const [selectedTipoDoc, setSelectedTipoDoc] = useState({
        tipoDocumento: tipoDocumentoProp,
    });

    const tipoDocs = servicioTipoDocumento.map(item => {
        const av = { tipoDocumento: item.nombretipodocumento }
        return av
    })

    const onTipoDocChange = (e) => {
        setSelectedTipoDoc(e.value);
        setValidarCambio(true)
    }

    const optionDisabledTipoDocumento = (e) => {
        var disabled = false
        if (tipoUsuario === 'Cliente') {
            if (e.tipoDocumento === 'Nit') {
                disabled = true
            }
        }
        return disabled
    }


    //Obtener datos del select tipo de cargo
    const [selectedCargo, setSelectedCargo] = useState({
        cargo: cargoProp,
        id: idCargoProp
    });

    const cargos = servicioCargo.map(item => {
        const av = { cargo: item.cargo, id: item.id }
        return av
    })

    const onCargoChange = (e) => {
        if (e.value.id !== idCargoProp) {
            setShowModal(true)
        }
        setSelectedCargo(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del select tipo de roles

    const [selectedRol, setSelectedRol] = useState({
        rol: rolProp,
        id: idRolProp,
        permisos: permisosProp
    });
    const roles = servicioRol.map(item => {
        const av = { rol: item.nombre, id: item.id, permisos: item.permisos }
        return av
    })
    if (selectedRol.rol === 'Super Admin') {
        roles.push({ rol: 'Super Admin', id: 1 })
    }

    const onRolChange = (e) => {
        setSelectedRol(e.value);
        setValidarCambio(true)
    }

    //Validar que si el rol tiene permiso de registrar actividad muestre horas mínimas y máximas
    const [tienePermiso, setTienePermiso] = useState(false)
    useEffect(() => {
        if (selectedRol.permisos !== '' && selectedRol.permisos !== undefined) {
            let getRol = JSON.parse(selectedRol.permisos)
            let arrayPermisos = getRol.rol
            if (arrayPermisos[1].permisos[0] !== '') {
                setTienePermiso(true)
            } else {
                setTienePermiso(false)
                setHourMin('')
                setHourMax('')
            }
        }
    }, [selectedRol])

    //Obtener datos del select tipo de estados

    const [selectedEstado, setSelectedEstado] = useState({
        estado: estadoProp,
        id: idEstadoProp
    });
    const estados = [
        { estado: "Activo", id: 1 }, //Activo
        { estado: "Inactivo", id: 0 }, //Inactivo
        { estado: "Eliminado", id: 3 }, //Eliminado
        { estado: "Bloqueado", id: 2 }, //Bloqueado

    ];

    const onEstadoChange = (e) => {
        if (e.value.id === 2) {
            setSelectedBloqueo({
                descripcion: '',
                id: '',
                edit: ''
            })
        } else {
            setSelectedBloqueo({
                descripcion: tipoBloqueoProp,
                id: idTipoBloqueoProp,
                edit: editProp
            })
        }
        setSelectedEstado(e.value);
        setValidarCambio(true)
    }

    //Obtener datos del select tipo proveedor

    const [selectProviderType, setSelectProviderType] = useState(null)

    const optionsProvidersType = servicioProveedor.map(item => {
        const av = { name: item.razonsocial, id: item.id }
        return av
    })

    const onChangeProviderTyoe = (e) => {
        setSelectProviderType(e.value)
        setMultiSelectedCliente([])
        /* setSelectedProyecto([]) */
        setValidarCambio(true)
    }

    const optionDisabledEstado = (e) => {
        /* console.log(e) */
        var disabled = false
        if (e.estado === 'Inactivo' || e.estado === 'Eliminado') {
            disabled = true
        }
        return disabled
    }
    //Obtener datos del select tipo de clientes
    //Función que carga lista de clientes por proveedores cada vez que se actualice un usuario tipo proveedor
    var listClientForSupplier = []
    const loadCustomer = () => {
        if (selectedTipoUser.tipoUsuario === 'Proveedor') {
            const result = servicioCliente.filter(function (obj) {
                var idSupplier
                obj.proveedores.forEach(i => {
                    idSupplier = i.proveedor.id
                })
                return idSupplier === selectProviderType.id
            })
            result.forEach(e => {
                listClientForSupplier.push(e)
            })

        } else {
            servicioCliente.forEach(e => {
                listClientForSupplier.push(e)
            })
        }
    }
    if (selectProviderType !== null) {
        loadCustomer()
    }
    const [multiSelectedCliente, setMultiSelectedCliente] = useState();
    const [multiSelectedClienteOld, setMultiSelectedClienteOld] = useState();

    var listClient = true
    if (selectedTipoUser.tipoUsuario === 'Proveedor') {
        listClient = false
    }
    const clientes = (listClient ? servicioCliente : listClientForSupplier).map(item => {
        const av = { name: item.razonsocial, id: item.id }
        return av
    })

    const [itemsSelectedClient, setItemsSelectedClient] = useState(0);
    const onClienteChange = (e) => {
        setItemsSelectedClient(e.value.length)
        setMultiSelectedCliente(e.value);
        setValidarCambio(true)
    }

    const [errorClientAsociations, setErrorClientAsociations] = useState(false)
    const [messageErrorAssociations, setMessageErrorAssociations] = useState('')
    useEffect(() => {
        if (multiSelectedCliente !== undefined && displayBasic === true) {
            const compararClients = (obj1, obj) => {
                return obj1.id === obj.id;
            };

            dataUserProp.forEach(newData => {
                if (newData.equipoTrabajoLider.length !== 0) {
                    newData.equipoTrabajoLider.forEach(equipo => {
                        if (equipo.estado === 1) {
                            const resultado = newData.equipoTrabajoLider.some(obj1 => multiSelectedCliente.some(obj2 => compararClients(obj1.equipoTrabajo.cliente, obj2)));
                            if (resultado === false) {
                                setErrorClientAsociations(true)
                                setMessageErrorAssociations(`El usuario tiene un equipo de trabajo asociado al cliente deseleccionado`)
                            } else {
                                setErrorClientAsociations(false)
                                setMessageErrorAssociations('')
                            }

                        }
                    })
                } else if (newData.equipoTrabajoUsuario.length !== 0) {
                    newData.equipoTrabajoUsuario.forEach(equipo => {
                        if (equipo.estado === 1) {
                            const resultado = newData.equipoTrabajoUsuario.some(obj1 => multiSelectedCliente.some(obj2 => compararClients(obj1.equipoTrabajo.cliente, obj2)));
                            if (resultado === false) {
                                setErrorClientAsociations(true)
                                setMessageErrorAssociations(`El usuario tiene un equipo de trabajo asociado al cliente deseleccionado`)
                            } else {
                                setErrorClientAsociations(false)
                                setMessageErrorAssociations('')
                            }
                        }
                    })
                }
            })
        }
    }, [multiSelectedCliente, displayBasic, usuarioProp, dataUserProp])// eslint-disable-line react-hooks/exhaustive-deps
    //Obtener datos del select tipo de proyecto


    const [selectedProyecto, setSelectedProyecto] = useState();
    const [selectedProyectoOld, setSelectedProyectoOld] = useState();

    var proyectoCliente = []
    var proyectoFechaVigente = []
    var d = new Date()

    const cargarProyectos = () => {
        if (multiSelectedCliente !== undefined) {
            if (multiSelectedCliente.length !== 0) {

                multiSelectedCliente.forEach(element => {
                    const result = servicioProyecto.filter(function (object) {
                        var idCliente = object.cliente.id
                        /* console.log(element.name) */
                        return idCliente === element.id
                    })

                    result.forEach(element => {
                        /* console.log(element) */
                        var proyectosSeleccionados = {}
                        proyectosSeleccionados.idCliente = element.cliente.id
                        proyectosSeleccionados.estado = null
                        proyectosSeleccionados.idR = null
                        proyectosSeleccionados.id = element.id
                        proyectosSeleccionados.name = element.nombreproyecto
                        proyectosSeleccionados.fechafinvigencia = element.fechafinvigencia
                        proyectoCliente.push(proyectosSeleccionados)
                    });
                    proyectoCliente.sort(function (a, b) {

                        var textA = a.name;
                        var textB = b.name;
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                })
                return proyectoFechaVigente
            }
        }

    }

    var dateValidError = false
    var messageDateValidError = ''

    if (multiSelectedCliente !== null) {
        cargarProyectos()
    }

    if (multiSelectedCliente !== undefined) {
        if (multiSelectedCliente?.length === 0) {
            dateValidError = false
            messageDateValidError = ''
        }
    }

    const proyectos = proyectoCliente.map(item => {
        const av = { idCliente: item.idCliente, estado: item.estado, idR: item.idR, id: item.id, name: item.name, fechafinvigencia: item.fechafinvigencia }
        return av
    })
    const [itemsSelectedProject, setItemsSelectedProject] = useState(0);
    const onProyectoChange = (e) => {
        const tableProjects = {}
        const uniqueTableProjects = e.value?.filter(function (object) {
            return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
        })
        setItemsSelectedProject(uniqueTableProjects.length)
        setSelectedProyecto(e.value);
        setValidarCambio(true)
    }

    const [deselectedProjects, setDeselectedProjects] = useState(false)
    useEffect(() => {

        if (selectedProyecto !== undefined && proyectosProp !== undefined && selectedProyecto?.length !== 0 && displayBasic === true) {
            let getProject = []
            proyectosProp.forEach(i => {
                const result = selectedProyecto?.filter(item => {
                    return item.id === i.proyecto.id
                })
                result?.forEach(e => {
                    getProject.push(e)
                })
            })
            if (getProject.length < proyectosProp.length) {
                setDeselectedProjects(true)
            } else {
                setDeselectedProjects(false)
            }
        }

    }, [selectedProyecto, proyectosProp, displayBasic])// eslint-disable-line react-hooks/exhaustive-deps

    //Obtener datos del select causal de bloqueo

    const [selectedBloqueo, setSelectedBloqueo] = useState({
        descripcion: tipoBloqueoProp,
        id: idTipoBloqueoProp,
        edit: editProp
    })

    const tiposBloqueos = servicioTipoBloqueo.map(item => {
        const av = { descripcion: item.descripcion, id: item.id, edit: item.eseditable }

        return av
    })

    const onBloqueoChange = (e) => {

        setSelectedBloqueo(e.value)
        setValidarCambio(true)
    }

    const optionDisabledTipoBloqueo = (e) => {
        var disabled = false
        if (e.edit === '0') {
            disabled = true
        }
        return disabled
    }

    //Obtener datos del input documento

    const [inputDoc, setInputDoc] = useState({
        documento: numeroDocumentoProp
    })

    const [mostrarProveedor, setMostrarProveedor] = useState(false)

    useEffect(() => {
        if (displayBasic === true) {
            if (selectedTipoUser.tipoUsuario === 'Proveedor') {
                setMostrarProveedor(true)
                setSelectProviderType(null)

            } else {

                setMostrarProveedor(false)

                /////Proceso pra evitar que los clientes se repitan 
                const multiSelectedClientes = []
                if (proyectosProp !== undefined) {
                    proyectosProp.forEach(element => {
                        var clientesSeleccionados = {}
                        clientesSeleccionados.id = element.proyecto.cliente.id
                        clientesSeleccionados.name = element.proyecto.cliente.razonsocial
                        multiSelectedClientes.push(clientesSeleccionados)

                    });
                }
                const tablaClientes = {}
                const clientesUnicos = multiSelectedClientes.filter(function (object) {
                    return tablaClientes.hasOwnProperty(object.id) ? false : (tablaClientes[object.id] = true)
                })

                setMultiSelectedCliente(clientesUnicos)
                setItemsSelectedClient(clientesUnicos.length)
                setMultiSelectedClienteOld(clientesUnicos)

                const selectedProyectos = []
                const selectedProyectosOld = []
                if (proyectosProp !== undefined) {
                    proyectosProp.forEach(element => {

                        var proyectosSeleccionados = {}
                        proyectosSeleccionados.idCliente = element.proyecto.cliente.id
                        proyectosSeleccionados.estado = null
                        proyectosSeleccionados.idR = null
                        proyectosSeleccionados.id = element.proyecto.id
                        proyectosSeleccionados.name = element.proyecto.nombreproyecto
                        proyectosSeleccionados.fechafinvigencia = element.proyecto.fechafinvigencia
                        selectedProyectos.push(proyectosSeleccionados)
                        var proyectosSeleccionadosOld = {}
                        proyectosSeleccionados.idCliente = element.proyecto.cliente.id
                        proyectosSeleccionadosOld.estado = element.estado
                        proyectosSeleccionadosOld.idR = element.id
                        proyectosSeleccionadosOld.id = element.proyecto.id
                        proyectosSeleccionadosOld.name = element.proyecto.nombreproyecto
                        proyectosSeleccionadosOld.fechafinvigencia = element.proyecto.fechafinvigencia
                        selectedProyectosOld.push(proyectosSeleccionadosOld)
                    });

                }

                const tableProjects = {}
                const uniqueTableProjects = selectedProyectos.filter(function (object) {
                    return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
                })
                setSelectedProyecto(uniqueTableProjects)
                setItemsSelectedProject(uniqueTableProjects.length)
                setSelectedProyectoOld(selectedProyectosOld)
            }

        }
        // console.log(selectedTipoUser.tipoUsuario);
    }, [selectedTipoUser.tipoUsuario, displayBasic, proyectosProp]); // eslint-disable-line
    const onDocChange = (event) => {

        setInputDoc({
            ...inputDoc,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input nombre

    const [inputNombre, setInputNombre] = useState({
        nombre: nombreProp
    })

    const onNombreChange = (event) => {

        setInputNombre({
            ...inputNombre,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input apellido

    const [inputApellido, setInputApellido] = useState({
        apellido: apellidoProp
    })

    const onApellidoChange = (event) => {

        setInputApellido({
            ...inputApellido,
            [event.target.name]: event.target.value
        })
        setValidarCambio(true)
    }

    //Obtener datos del input Contraseña

    const [inputContraseña, setInputContraseña] = useState({
        contraseña: contraseñaProp
    })

    const onContraseñaChange = (event) => {

        setInputContraseña({
            ...inputContraseña,
            [event.target.name]: event.target.value
        })
    }


    var gmtHours = -d.getTimezoneOffset() / 60;

    const [date, setDate] = useState(null);
    const [guardarFecha, setGuardarFecha] = useState(null);
    const [lastDateRegister, setLastDateRegister] = useState(null);
    const [saveLastDateRegister, setSaveLastDateRegister] = useState(null);

    useEffect(() => {
        setDate(fechaingresoProp)
        setLastDateRegister(lastDateRegisterProp)
    }, [fechaingresoProp, lastDateRegisterProp])

    useEffect(() => {

        if (fechaingresoProp !== null) {
            var fechaConvertida = new Date(fechaingresoProp)
            if (gmtHours < 0) {
                setGuardarFecha(SumarDias(fechaConvertida, +1))
            } else {
                setGuardarFecha(fechaConvertida)
            }
        } else {
            setGuardarFecha(null)
        }
        if (lastDateRegisterProp !== null) {
            var changeDate = new Date(lastDateRegisterProp)
            if (gmtHours < 0) {
                setSaveLastDateRegister(SumarDias(changeDate, +1))
            } else {
                setSaveLastDateRegister(changeDate)
            }
        } else {
            setSaveLastDateRegister(null)
        }

    }, [fechaingresoProp, lastDateRegisterProp, gmtHours])

    const onConvertirFechaChange = (e) => {

        let fecha = e.value
        if (fecha !== null) {
            const fecha2 = CambiarFormatoFecha(fecha)
            setDate(fecha2);
            dateError = false
            messageDateError = ''
        } else {
            setDate(null);
            setGuardarFecha(null)
        }
        setValidarCambio(true)

    }

    const onLastDateRegisterChange = (e) => {

        let fecha = e.value
        if (fecha !== null) {
            const fecha2 = CambiarFormatoFecha(fecha)
            setLastDateRegister(fecha2);
        } else {
            setLastDateRegister(null);
            setSaveLastDateRegister(null)
        }
        setValidarCambio(true)
    }

    //Fecha Min Y Max de historico de horas

    const [dateHistoryHours, setDateHistoryHours] = useState(null)

    const onDateHistoryHoursChange = (e) => {
        setDateHistoryHours(e.value)
    }

    const [errorDateHistoryHours, setErrorDateHistoryHours] = useState(false)
    const [messageErrorDateHistoryHours, setMessageErrorDateHistoryHours] = useState('')
    useEffect(() => {
        if (displayBasic === true) {
            const filterHistorico = historicoHorasProp.filter(item => item.estado === 1)
            if (dateHistoryHours !== null) {
                filterHistorico.forEach(e => {
                    if (e !== null) {
                        if (CambiarFormatoFecha(dateHistoryHours) < e.fechainicioasignacion) {
                            setErrorDateHistoryHours(true)
                            setMessageErrorDateHistoryHours('La fecha fin no puede ser menor a la fecha inicio del histórico en estado abierto')
                        } else {
                            setErrorDateHistoryHours(false)
                            setMessageErrorDateHistoryHours('')
                        }
                    }
                })

            }
        }


    }, [dateHistoryHours, displayBasic, historicoHorasProp])


    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    //Horas mínimas

    const [hourMin, setHourMin] = useState('')
    const [valueValidNumber, setValueValidNumber] = useState(false)
    const [messageValidNumber, setMessageValidNumber] = useState('')
    const onChangeHourMin = (e) => {
        setHourMin(e.target.value)
        setValidarCambio(true)
    }

    //Horas máximas
    const [valueValidNumberMax, setValueValidNumberMax] = useState(false)
    const [messageValidNumberMax, setMessageValidNumberMax] = useState('')
    const [hourMax, setHourMax] = useState('')

    const onChangeHourMax = (e) => {
        setHourMax(e.target.value)
        setValidarCambio(true)
    }
    //Validar que si cambian las horasMinimas o Máximas salga el campo de fecha fin de histórico

    useEffect(() => {
        const filterHistorico = historicoHorasProp.filter(obj => {
            return obj.estado === 1
        })
        filterHistorico.forEach(e => {
            if (parseFloat(e.horasMinimas) !== parseFloat(hourMin) ||
                parseFloat(e.horasMaximas) !== parseFloat(hourMax)) {

                setChangeHour(true)
            } else {
                setChangeHour(false)
            }
        })


    }, [hourMin, hourMax, historicoHorasProp])

    useEffect(() => {

        if (displayBasic === true) {

            if (hourMax !== '') {
                const getValidNumberMax = isValidNumber(hourMax)
                if (getValidNumberMax === false) {
                    setValueValidNumberMax(true)
                    setMessageValidNumberMax('Debe ingresar números enteros o decimales')
                } else {
                    setValueValidNumberMax(false)
                    setMessageValidNumberMax('')
                }
            }
            if (hourMin !== '') {
                const getValidNumber = isValidNumber(hourMin)
                if (getValidNumber === false) {
                    setValueValidNumber(true)
                    setMessageValidNumber('Debe ingresar números enteros o decimales')
                } else {
                    setValueValidNumber(false)
                    setMessageValidNumber('')
                }
            }

            function isValidNumber(value) {
                // Verifica si el valor es un número válido
                return /^\d+(\.\d+)?$/.test(value);
            }
        }
    }, [hourMin, hourMax, displayBasic])

    //Validar si hubo algun cambio 

    var Cambios = false
    if (validarCambio === true) {
        Cambios = true
    }

    const [newRunRender, setNewRunRender] = useState(0)
    //Actualizamos los estados cada vez que el componete se renderice, es decir cada vez que sufre un cambio
    useEffect(() => {
        if (displayBasic === true) {

            setSelectedTipoUser({
                tipoUsuario: tipoUsuarioProp,
                id: idTipoUsuarioProp
            })
            setSelectedTipoDoc({
                tipoDocumento: tipoDocumentoProp
            })
            setSelectedCargo({
                cargo: cargoProp,
                id: idCargoProp
            })
            setSelectedRol({
                rol: rolProp,
                id: idRolProp,
                permisos: permisosProp
            })
            setSelectedEstado({
                estado: estadoProp,
                id: idEstadoProp
            })
            setInputDoc({
                documento: numeroDocumentoProp
            })
            setInputContraseña({
                contraseña: contraseñaProp
            })
            setInputNombre({
                nombre: nombreProp
            })
            setInputApellido({
                apellido: apellidoProp
            })
            setSelectedBloqueo({
                descripcion: tipoBloqueoProp,
                id: idTipoBloqueoProp,
                edit: editProp
            })
            setDate(fechaingresoProp)
            if (supplierProp !== null) {
                setSelectProviderType({
                    name: supplierProp.razonsocial,
                    id: supplierProp.id
                })
            }

            /////Proceso pra evitar que los clientes se repitan 
            const multiSelectedClientes = []
            if (proyectosProp !== undefined) {
                proyectosProp.forEach(element => {
                    var clientesSeleccionados = {}
                    clientesSeleccionados.id = element.proyecto.cliente.id
                    clientesSeleccionados.name = element.proyecto.cliente.razonsocial
                    multiSelectedClientes.push(clientesSeleccionados)

                });
            }
            const tablaClientes = {}
            const clientesUnicos = multiSelectedClientes.filter(function (object) {
                return tablaClientes.hasOwnProperty(object.id) ? false : (tablaClientes[object.id] = true)
            })

            setMultiSelectedCliente(clientesUnicos)
            setItemsSelectedClient(clientesUnicos.length)
            setMultiSelectedClienteOld(clientesUnicos)

            const selectedProyectos = []
            const selectedProyectosOld = []
            if (proyectosProp !== undefined) {
                proyectosProp.forEach(element => {

                    var proyectosSeleccionados = {}
                    proyectosSeleccionados.idCliente = element.proyecto.cliente.id
                    proyectosSeleccionados.estado = null
                    proyectosSeleccionados.idR = null
                    proyectosSeleccionados.id = element.proyecto.id
                    proyectosSeleccionados.name = element.proyecto.nombreproyecto
                    proyectosSeleccionados.fechafinvigencia = element.proyecto.fechafinvigencia
                    selectedProyectos.push(proyectosSeleccionados)
                    var proyectosSeleccionadosOld = {}
                    proyectosSeleccionados.idCliente = element.proyecto.cliente.id
                    proyectosSeleccionadosOld.estado = element.estado
                    proyectosSeleccionadosOld.idR = element.id
                    proyectosSeleccionadosOld.id = element.proyecto.id
                    proyectosSeleccionadosOld.name = element.proyecto.nombreproyecto
                    proyectosSeleccionadosOld.fechafinvigencia = element.proyecto.fechafinvigencia
                    selectedProyectosOld.push(proyectosSeleccionadosOld)
                });

            }

            const tableProjects = {}
            const uniqueTableProjects = selectedProyectos.filter(function (object) {
                return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
            })
            setSelectedProyecto(uniqueTableProjects)
            setItemsSelectedProject(uniqueTableProjects.length)
            setSelectedProyectoOld(selectedProyectosOld)
            historicoHorasProp.forEach(e => {
                if (e.estado === 1) {
                    setHourMin(e.horasMinimas)
                    setHourMax(e.horasMaximas)
                }
            })

        }

    }, [tipoUsuarioProp, numeroDocumentoProp, tipoDocumentoProp, nombreProp, apellidoProp, cargoProp, idCargoProp, usuarioProp, rolProp, idRolProp,
        estadoProp, idEstadoProp, contraseñaProp, proyectosProp, fechaingresoProp,
        tipoBloqueoProp, idTipoBloqueoProp, editProp, supplierProp,
        newRunRender, displayBasic, historicoHorasProp, permisosProp, idTipoUsuarioProp]) // eslint-disable-line react-hooks/exhaustive-deps

    //Almacenamos los valores para enviarlos por props
    const tipoUsuario = selectedTipoUser.tipoUsuario
    const numeroDoc = inputDoc.documento
    const tipoDocumento = selectedTipoDoc.tipoDocumento
    const idTipoDocumento = selectedTipoDoc.id
    const nombre = inputNombre.nombre
    const apellido = inputApellido.apellido
    const bloqueo = selectedBloqueo.descripcion
    const idBloqueo = selectedBloqueo.id
    const cargo = selectedCargo.cargo
    const idCargo = selectedCargo.id
    const contraseña = inputContraseña.contraseña
    const rol = selectedRol.rol
    const estado = selectedEstado.estado
    const idEstado = selectedEstado.id
    const idRol = selectedRol.id
    const usuario = usuarioProp
    const fecha = date
    const usuarioSesion = cookies.get('usuario')


    // Modal para el botón regresar

    const ModalRegresar = () => {

        const [displayConfirmation, setDisplayConfirmation] = useState(false);
        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation,

        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);

        }

        const Regresar = (name) => {
            setSelectedTipoUser({
                tipoUsuario: tipoUsuarioProp,
                id: idTipoUsuarioProp
            })
            setSelectedTipoDoc({
                tipoDocumento: tipoDocumentoProp
            })
            setSelectedCargo({
                cargo: cargoProp,
                id: idCargoProp
            })
            setSelectedRol({
                rol: rolProp,
                id: idRolProp,
                permisos: permisosProp
            })
            setSelectedEstado({
                estado: estadoProp,
                id: idEstadoProp
            })
            setInputDoc({
                documento: numeroDocumentoProp
            })
            setInputContraseña({
                contraseña: contraseñaProp
            })
            setInputNombre({
                nombre: nombreProp
            })
            setInputApellido({
                apellido: apellidoProp
            })
            setSelectedBloqueo({
                descripcion: tipoBloqueoProp,
                id: idTipoBloqueoProp,
                edit: editProp
            })

            setDate(fechaingresoProp)
            if (fechaingresoProp !== null) {
                var fechaConvertida = new Date(fechaingresoProp)
                if (gmtHours < 0) {
                    setGuardarFecha(SumarDias(fechaConvertida, +1))
                } else {
                    setGuardarFecha(fechaConvertida)
                }
            }
            if (lastDateRegisterProp !== null) {
                var changeDate = new Date(lastDateRegisterProp)
                if (gmtHours < 0) {
                    setSaveLastDateRegister(SumarDias(changeDate, +1))
                } else {
                    setSaveLastDateRegister(changeDate)
                }
            }
            setLastDateRegister(lastDateRegisterProp)
            const multiSelectedClientes = []

            if (proyectosProp !== undefined) {
                proyectosProp.forEach(element => {
                    var clientesSeleccionados = {}
                    clientesSeleccionados.id = element.proyecto.cliente.id
                    clientesSeleccionados.name = element.proyecto.cliente.razonsocial
                    multiSelectedClientes.push(clientesSeleccionados)
                });

            }
            const tablaClientes = {}
            const clientesUnicos = multiSelectedClientes.filter(function (object) {
                return tablaClientes.hasOwnProperty(object.id) ? false : (tablaClientes[object.id] = true)
            })
            setMultiSelectedCliente(clientesUnicos)
            if (supplierProp !== null) {
                setSelectProviderType({
                    name: supplierProp.razonsocial,
                    id: supplierProp.id
                })
            }
            const selectedProyectos = []
            const selectedProyectosOld = []
            if (proyectosProp !== undefined) {
                proyectosProp.forEach(element => {

                    var proyectosSeleccionados = {}
                    proyectosSeleccionados.idCliente = element.proyecto.cliente.id
                    proyectosSeleccionados.estado = null
                    proyectosSeleccionados.idR = null
                    proyectosSeleccionados.id = element.proyecto.id
                    proyectosSeleccionados.name = element.proyecto.nombreproyecto
                    proyectosSeleccionados.fechafinvigencia = element.proyecto.fechafinvigencia
                    selectedProyectos.push(proyectosSeleccionados)
                    var proyectosSeleccionadosOld = {}
                    proyectosSeleccionados.idCliente = element.proyecto.cliente.id
                    proyectosSeleccionadosOld.estado = element.estado
                    proyectosSeleccionadosOld.idR = element.id
                    proyectosSeleccionadosOld.id = element.proyecto.id
                    proyectosSeleccionadosOld.name = element.proyecto.nombreproyecto
                    proyectosSeleccionadosOld.fechafinvigencia = element.proyecto.fechafinvigencia
                    selectedProyectosOld.push(proyectosSeleccionadosOld)
                });

            }

            const tableProjects = {}
            const uniqueTableProjects = selectedProyectos.filter(function (object) {
                return tableProjects.hasOwnProperty(object.id) ? false : (tableProjects[object.id] = true)
            })
            setSelectedProyecto(uniqueTableProjects)
            setItemsSelectedProject(uniqueTableProjects.length)
            setSelectedProyectoOld(selectedProyectosOld)

            setValidarCambio(false)
            setDisplayBasic(false)
            dialogFuncMap[`${name}`](false);
            historicoHorasProp.forEach(e => {
                if (e.estado === 1) {
                    setHourMin(e.horasMinimas)
                    setHourMax(e.horasMaximas)
                }
            })
            setChangeHour(false)
            setDateHistoryHours(null)
            setErrorDateHistoryHours(false)
            setMessageErrorDateHistoryHours('')
        }
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }

        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />

                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}} footer={renderFooter('displayConfirmation')} onHide={() => onHide('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado la información registrada, ¿desea continuar?</span>

                    </div>
                </Dialog>

            </>
        )
    }


    let dataHourHistory = []
    if (changeHour === true) {
        const dateToAssign = dateHistoryHours !== null ? SumarDias(new Date(dateHistoryHours), 1) : '';
        const historicoHoras = historicoHorasProp.map(item => {
            if (item.estado === 1) {
                return {
                    ...item,
                    estado: 0,
                    fechafinasignacion: dateHistoryHours,
                };
            } else {
                return {
                    ...item,
                    estado: 0,
                };
            }
        });

        const nuevoRegistro = {
            id: '',
            horasMinimas: hourMin,
            horasMaximas: hourMax,
            fechainicioasignacion: dateToAssign,
            fechafinasignacion: '',
            estado: 1,
            fechacreacion: ''
        }
        historicoHoras.push(nuevoRegistro);
        dataHourHistory = historicoHoras
    } else {
        dataHourHistory = historicoHorasProp
    }

    const [updatePositionHistory, setUpdatePositionHistory] = useState(false);
    var updateStatusHistory = false
    if (idEstadoProp !== selectedEstado.id) {
        updateStatusHistory = true
    }

    const renderFooter = (name) => {
        return (
            <div>
                {Cambios ?
                    <ModalRegresar />
                    :
                    <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }

                {permisoActualizar ?
                    <ConfirmBtn
                        titulo="Confirmar Actualización"
                        mensaje="¿Estás seguro de guardar esta información?"
                        accion="Actualizar"
                        icono="pi pi-pencil"
                        label="Guardar"
                        updateDateprop={false}
                        numeroDocProp={numeroDoc}
                        tipoDocumentoProp={tipoDocumento}
                        idTipoDocumentoProp={idTipoDocumento}
                        nombreProp={nombre}
                        apellidoProp={apellido}
                        bloqueoProp={bloqueo}
                        idBloqueoProp={idBloqueo}
                        cargoProp={cargo}
                        idCargoProp={idCargo}
                        cargosProp={servicioCargo}
                        rolProp={rol}
                        estadoProp={estado}
                        idEstadoProp={idEstado}
                        intentosProp={intentosProp}
                        idRolProp={idRol}
                        usuarioProp={usuario}
                        proyectosProp={selectedProyecto}
                        proyectosOldProp={selectedProyectoOld}
                        fechaIngresoProp={fecha}
                        lastDateRegisterProp={lastDateRegister}
                        creadoporProp={creadoporProp}
                        fechaCreacionProp={fechaCreacionProp}
                        fechaactualizadoProp={fechaactualizadoProp}
                        lastIdCargoProp={idCargoProp}
                        usuarioSesionProp={usuarioSesion}
                        updatePositionHistoryProp={updatePositionHistory}
                        updateStatusHistoryProp={updateStatusHistory}
                        lastIdEstadoProp={idEstadoProp}
                        lastIdTipoBloqueo={idTipoBloqueoProp}
                        lastFechaingresoProp={fechaingresoProp}
                        dataCompletedProp={dataCompletedProp}
                        historicoHorasProp={dataHourHistory}
                        ProveedorSeleccionadoProp={selectProviderType?.id}
                        idTipoUsuarioProp={selectedTipoUser?.id}
                        eliminarCargoProp={false}
                    />
                    : ""
                }

            </div>
        );
    }

    var condicion = false
    if (tipoUsuario === 'Colaborador' || tipoUsuario === 'Cliente' || tipoUsuario === 'Proveedor') {
        condicion = true
    }


    //Validaciones para Documento
    var documentoError = false
    var mensajeDocumento = ''
    if (numeroDoc === '') {
        documentoError = true
        mensajeDocumento = 'Debe ingresar un número de documento'
    } else if (numeroDoc.length < 5) {
        documentoError = true
        mensajeDocumento = 'El número de documento debe ser mayor a 5 dígitos '
    } else if (numeroDoc.length > 12) {
        documentoError = true
        mensajeDocumento = 'El número de documento debe ser menor a 12 dígitos'
    }

    if (tipoDocumento !== 'Pasaporte' && tipoDocumento !== "Cédula Extranjería") {
        if (isNaN(numeroDoc) === true) {
            documentoError = true
            mensajeDocumento = 'Este tipo de documento no puede contener letras'
        }
    }

    //validaciones para tipo de docemento
    var tipoDocumentoError = false
    var mensajeTipoDocumento = ''
    if (tipoDocumento === '') {
        tipoDocumentoError = true
        mensajeTipoDocumento = 'Debe ingresar un tipo de documento '
    }

    //Validaciones para Clientes
    var clientesError = false
    var mensajeClientes = ''

    //Validar que cliente se deshabilite cuando se seleccione un proveedor
    var disabledClient
    if (selectProviderType !== null) {
        disabledClient = false
        if (listClientForSupplier.length === 0) {
            clientesError = true
            mensajeClientes = 'El proveedor no tiene un cliente asociado'
        }
    } else {
        disabledClient = true
    }

    // si desasocian un cliente primero que el proyecto 
    /* console.log(selectedProyecto) */
    const ClientesDesseleccionados = []

    const validarDesAsociacion = () => {
        multiSelectedClienteOld.forEach(i => {
            var existe = false
            multiSelectedCliente.forEach(j => {
                !existe && i.id === j.id && (existe = true)
            })
            !existe && ClientesDesseleccionados.push({
                id: i.id,
                name: i.name,
            })

        })

    }
    if (multiSelectedCliente !== null) {
        if (multiSelectedCliente !== undefined) {
            validarDesAsociacion()
        }
    }
    if (ClientesDesseleccionados !== undefined) {
        if (ClientesDesseleccionados.length !== 0) {
            if (ClientesDesseleccionados.length > 0) {
                ClientesDesseleccionados.forEach(element => {
                    const result = selectedProyecto.filter(function (object) {

                        return object.idCliente === element.id
                    })
                    if (result !== null) {
                        if (result.length !== 0) {
                            if (result.length > 0) {
                                clientesError = true
                                mensajeClientes = 'Debe desasociar el proyecto antes que el cliente '
                            }
                        }
                    }
                })
            }
        }

    }

    ////validaciones proyectos
    var proyectosError = false
    var mensajeProyectos = ''
    if (multiSelectedCliente !== undefined) {
        if (selectedProyecto === '' || selectedProyecto?.length === 0) {
            proyectosError = true
            mensajeProyectos = 'Debe asociar almenos un proyecto '
        }
    }


    //validaciones para nombre
    var nombreError = false
    var mensajeNombre = ''
    if (nombre) {
        if (nombre === '' || nombre === null) {
            nombreError = true
            mensajeNombre = 'Debe ingresar un nombre'
        } else if (nombre.length <= 1) {
            nombreError = true
            mensajeNombre = 'Debe ingresar un nombre de más de 1 caracteres'
        } else if (nombre.length > 100) {
            nombreError = true
            mensajeNombre = 'Debe ingresar un nombre de menos de 100 caracteres'
        } else {
            nombreError = false
            mensajeNombre = ''
        }
    }

    //validaciones para apellido
    var apellidoError = false
    var mensajeApellido = ''
    if (apellido) {
        if (apellido === '' || apellido === null) {
            apellidoError = true
            mensajeApellido = 'Debe ingresar un apellido '
        } else if (apellido.length <= 1) {
            apellidoError = true
            mensajeApellido = 'Debe ingresar un apellido de más de 1 caracteres'
        } else if (apellido.length > 100) {
            apellidoError = true
            mensajeApellido = 'Debe ingresar un apellido de menos de 100 caracteres'
        } else {
            apellidoError = false
            mensajeApellido = ''
        }
    }

    //validaciones para causal de bloqueo
    var bloqueoError = false
    var mensajeBloqueo = ''

    if (bloqueo) {
        if (bloqueo === '') {
            bloqueoError = true
            mensajeBloqueo = 'Debe ingresar una causal de bloqueo'
        }
    }


    //validación fecha ingreso
    var dateError = false
    var messageDateError = ''
    if (estadoProp === "Bloqueado" && selectedEstado.id === 1 && tipoBloqueoProp === 'Retiro' && fechaingresoProp === date) {
        dateError = true
        messageDateError = 'Debe ingresar una nueva fecha de ingreso'
    }


    //validaciones para cargo
    var cargoError = false
    var mensajeCargo = ''
    if (cargo === '') {
        cargoError = true
        mensajeCargo = 'Debe ingresar un cargo'
    }
    //validaciones para rol
    var rolError = false
    var mensajeRol = ''
    if (rol === '') {
        rolError = true
        mensajeRol = 'Debe ingresar un rol'
    }


    // modal de advertencia 

    const ModalWarning = ({ showModalProp, messageProp }) => {

        const [displayBasic, setDisplayBasic2] = useState(showModalProp);

        const dialogFuncMap = {
            'displayBasic': setDisplayBasic2,
        }

        const onHide = (name, boolean) => {
            setDisplayBasic2(false)
            dialogFuncMap[`${name}`](false);
            setShowModal(false)
            setUpdatePositionHistory(boolean)
        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => onHide(name, false)} className="p-button-text" />
                    <Button label="Si" icon="pi pi-check" onClick={() => onHide(name, true)} autoFocus />
                </div>
            );
        }

        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog className="dialog-demo" header="Advertencia" visible={displayBasic} modal footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')} breakpoints={{ '960px': '75vw' }} >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            <span style={{ textAlign: 'center' }}>¿Desea guardar el histórico del cargo anterior?</span>
                        </div>
                    </Dialog>
                </div>
            </div>
        )
    }

    //Validar que horas míminas no sea mayor que las horas máximas
    let errorHour = false
    let messageHourError = ''
    let errorHourMax = false
    let messageHourErrorMax = ''
    if (hourMin !== '' && hourMax !== '') {

        if (parseFloat(hourMin) > parseFloat(hourMax)) {
            errorHour = true
            messageHourError = 'Las horas mínimas no pueden ser mayor a las horas máximas'
        } else if (hourMax > 24) {
            errorHourMax = true
            messageHourErrorMax = 'No se permite registrar más de 24h'
        } else {
            errorHour = false
            messageHourError = ''
        }
    }

    //Validar que no hay ningun error
    var permisoActualizar = false
    var validateSuperAdmin = false
    if (rol !== 'Super Admin') {
        if (selectedTipoUser.tipoUsuario !== 'Administrador' && documentoError === false && tipoDocumentoError === false && clientesError === false && proyectosError === false && nombreError === false && cargoError === false &&
            rolError === false && date !== null && (bloqueo !== undefined && bloqueo !== '') && apellidoError === false && errorClientAsociations === false
            && errorHour === false && errorHourMax === false && valueValidNumber === false
            && valueValidNumberMax === false && errorDateHistoryHours === false) {
            if (selectedEstado.id === 2 && selectedBloqueo.descripcion === 'Retiro') {
                if (lastDateRegister !== null) {
                    permisoActualizar = true
                } else {
                    permisoActualizar = false
                }
            } else if (tienePermiso === true && (hourMin === '' || hourMax === '')) {
                permisoActualizar = false
            } else if (changeHour === true && dateHistoryHours === null) {
                permisoActualizar = false
            } else if (selectedTipoUser.tipoUsuario === 'Proveedor' && selectProviderType === null) {
                permisoActualizar = false
            }
            else {
                permisoActualizar = true
            }

        }
    } else {
        validateSuperAdmin = true
        if (selectedTipoUser.tipoUsuario !== 'Administrador' && documentoError === false && tipoDocumentoError === false && nombreError === false && cargoError === false &&
            rolError === false && bloqueo !== undefined && bloqueo !== '' && apellidoError === false
            && errorClientAsociations === false && errorHour === false &&
            errorHourMax === false && valueValidNumber === false &&
            valueValidNumberMax === false && errorDateHistoryHours === false) {
            if (selectedEstado.id === 2 && selectedBloqueo.descripcion === 'Retiro') {
                if (lastDateRegister !== null) {
                    permisoActualizar = true
                } else {
                    permisoActualizar = false
                }

            } else if (tienePermiso === true && (hourMin === '' || hourMax === '')) {
                permisoActualizar = false
            }
            else if (changeHour === true && dateHistoryHours === null) {
                permisoActualizar = false
            } else if (selectedTipoUser.tipoUsuario === 'Proveedor' && selectProviderType === null) {
                permisoActualizar = false
            }
            else {
                permisoActualizar = true
            }
        }
    }


    var validarPasaporte = false

    if (selectedTipoDoc.tipoDocumento === "Pasaporte" || selectedTipoDoc.tipoDocumento === "Cédula Extranjería") {
        validarPasaporte = true
    }

    var validarBloqueo = false
    let validarRetiro = false
    if (estado === "Bloqueado") {
        validarBloqueo = true
    }
    if (selectedBloqueo.descripcion !== '' && selectedBloqueo.descripcion === 'Retiro') {
        validarRetiro = true
    }

    /// que se ingrese un cliente 
    var disabledProyecto
    if (multiSelectedCliente !== null) {
        if (multiSelectedCliente !== undefined) {
            if (multiSelectedCliente.length === 0) {
                disabledProyecto = true
            } else {
                disabledProyecto = false
            }
        }

    } else {
        disabledProyecto = true
    }

    //Deshabilitar boton editar cuando usuario tiene estado: Elimado
    var disabledButtonEdit = false
    if (idEstado === 3) {
        disabledButtonEdit = true
    }

    //Deshabilitar el campo rol cuando es tipo proveedor o super admin
    var disabledRol = false
    if (rol === 'Super Admin') {
        disabledRol = true
    }
    // cuando es tipo cliente

    if (tipoUsuario === 'Cliente') {
        disabledClient = true
    }
    return (

        <>
            <ModalWarning
                showModalProp={showModal}
            />
            {
                deselectedProjects === true ?
                    <ModalAdvertencia
                        usuarioProp={usuarioProp}
                        newRunRenderProp={newRunRender}
                        setNewRunRenderProp={setNewRunRender}
                        setRedirectProp={setRedirectProp}
                        setDisplayBasicProp={setDisplayBasic}
                        setValueUniqueProp={setValueUniqueProp}
                    />
                    :
                    ''
            }

            <Button icon="pi pi-pencil" className="btnEditar p-button-secondary" onClick={() => onClick('displayBasic')}
                disabled={disabledButtonEdit} tooltip="Editar" tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
            ></Button>
            <Dialog className="modal" header="Actualizar Usuario" visible={displayBasic} style={{}} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')}>
                <div className="p-fluid p-formgrid p-grid">

                    <form style={{ display: 'contents', textAlign: 'left' }} >
                        <div className="p-field p-col-12 p-md-12">
                            <label className="labels" htmlFor="tipoDoc">Tipo Usuario<span className="require">*</span></label>
                            <Dropdown value={selectedTipoUser} options={tipoUsers} onChange={onTipoUserChange} name="tipoUsuario" optionLabel="tipoUsuario" placeholder="Seleccione tipo de Usuario" />

                        </div>

                        {condicion ?
                            <>
                                {
                                    mostrarProveedor ?
                                        <>
                                            <div className="p-field p-col-12 p-md-6">
                                                <label className="labels" htmlFor="tipoDoc">Proveedor<span className="require">*</span></label>
                                                <Dropdown value={selectProviderType} options={optionsProvidersType} onChange={onChangeProviderTyoe} optionLabel="name"
                                                    placeholder="Seleccione un proveedor" filter
                                                />
                                            </div>
                                            {/*  <div className="p-field p-col-12 p-md-6">
                                                <label className="labels" htmlFor="tipoDoc">Cliente<span className="require">*</span></label>
                                                <MultiSelect value={multiSelectedCliente} options={clientes} onChange={onClienteChange} optionLabel="name" name="cliente"
                                                    placeholder="Seleccione un Cliente" disabled={disabledClient}
                                                    className={clientesError || errorClientAsociations ? "p-invalid" : ""} filter maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`}
                                                />
                                                <p className="mensajeError">{clientesError ? mensajeClientes : ""}{errorClientAsociations ? messageErrorAssociations : ""}</p>
                                            </div> */}
                                        </>
                                        :
                                        ''
                                }
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" htmlFor="tipoDoc">Cliente {validateSuperAdmin ? '' : <span className="require">*</span>}</label>
                                    <MultiSelect value={multiSelectedCliente} options={clientes} onChange={onClienteChange} optionLabel="name" name="cliente"
                                        placeholder="Seleccione un Cliente" disabled={disabledClient}
                                        className={(clientesError && validateSuperAdmin === false) || errorClientAsociations ? "p-invalid" : ""} filter maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedClient} Elementos Seleccionados`}
                                    />
                                    <p className="mensajeError">{clientesError && validateSuperAdmin === false ? mensajeClientes : ""}{errorClientAsociations ? messageErrorAssociations : ""}</p>
                                </div>

                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" htmlFor="tipoDoc">Proyecto {validateSuperAdmin ? '' : <span className="require">*</span>}</label>
                                    <MultiSelect value={selectedProyecto} options={proyectos} onChange={onProyectoChange} optionLabel="name" name="proyecto"
                                        placeholder="Seleccione un Proyecto" disabled={disabledProyecto}
                                        className={(proyectosError || dateValidError) && validateSuperAdmin === false ? "p-invalid" : ""} filter maxSelectedLabels={3} selectedItemsLabel={`${itemsSelectedProject} Elementos Seleccionados`}
                                    />
                                    <p className="mensajeError">{proyectosError && validateSuperAdmin === false ? mensajeProyectos : ""}</p>
                                    <p className="mensajeError">{dateValidError ? messageDateValidError : ""}</p>
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" htmlFor="rol">Rol<span className="require">*</span></label>
                                    <Dropdown value={selectedRol} options={roles} onChange={onRolChange} name="rol" optionLabel="rol"
                                        placeholder="Seleccione Rol"
                                        className={rolError ? "p-invalid" : ""} disabled={disabledRol}
                                    />
                                    <p className="mensajeError">{rolError ? mensajeRol : ""}</p>
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" htmlFor="estado">Estado<span className="require">*</span></label>
                                    <Dropdown value={selectedEstado} options={estados} onChange={onEstadoChange} name="estado" optionLabel="estado" placeholder="Seleccione Estado"
                                        optionDisabled={optionDisabledEstado}
                                    />
                                </div>
                                {
                                    validarBloqueo ?
                                        <div className="p-field p-col-12 p-md-3">
                                            <label className="labels" htmlFor="bloqueo">Tipo de bloqueo<span className="require">*</span></label>
                                            <Dropdown value={selectedBloqueo} options={tiposBloqueos} onChange={onBloqueoChange} name="descripcion" optionLabel="descripcion"
                                                placeholder="Seleccione tipo de bloqueo" optionDisabled={optionDisabledTipoBloqueo}
                                                className={bloqueoError ? "p-invalid" : ""}
                                            />
                                            <p className="mensajeError">{bloqueoError ? mensajeBloqueo : ""}</p>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    validarRetiro ?
                                        <div className="p-field p-col-12 p-md-3">
                                            <label className="labels" >Último día de registro<span className="require">*</span></label>
                                            <Calendar placeholder="Ingrese una fecha " value={saveLastDateRegister} onChange={onLastDateRegisterChange} dateFormat="dd-mm-yy" locale="es"
                                                monthNavigator yearNavigator yearRange="2010:2030"
                                            />
                                        </div>
                                        :
                                        ""
                                }
                                <div className={validarBloqueo ? "p-field p-col-12 p-md-3" : "p-field p-col-12 p-md-6"}>
                                    <label className="labels" htmlFor="tipoDoc">Tipo documento<span className="require">*</span></label>
                                    <Dropdown value={selectedTipoDoc} options={tipoDocs} onChange={onTipoDocChange} name="tipoDocumento" optionLabel="tipoDocumento"
                                        placeholder="Seleccione tipo de documento" optionDisabled={optionDisabledTipoDocumento}
                                        className={tipoDocumentoError ? "p-invalid" : ""}
                                    />
                                    <p className="mensajeError">{tipoDocumentoError ? mensajeTipoDocumento : ""}</p>
                                </div>

                                <div className={validarBloqueo ? "p-field p-col-12 p-md-3" : "p-field p-col-12 p-md-6"}>
                                    <label className="labels" htmlFor="numDoc">Número documento<span className="require">*</span></label>
                                    <InputText id="numDoc" type="text" name="documento" keyfilter={validarPasaporte ? "alphanum" : "pint"} onChange={onDocChange}
                                        className={documentoError ? "p-invalid" : ""} value={numeroDoc} />
                                    <p className="mensajeError">{documentoError ? mensajeDocumento : ""}</p>
                                </div>

                                <>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="nombre">Nombre<span className="require">*</span></label>
                                        <InputText id="nombre" type="text" name="nombre" keyfilter={/^[^<0-9>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/} onChange={onNombreChange} placeholder="Ingrese un nombre" value={nombre}
                                            className={nombreError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{nombreError ? mensajeNombre : ""}</p>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label className="labels" htmlFor="apellido">Apellido<span className="require">*</span></label>
                                        <InputText id="apellido" type="text" name="apellido" keyfilter={/^[^<0-9>*!=°|(#$%&"_)+-.:;,/?¡¿'{}]+$/} onChange={onApellidoChange} placeholder="Ingrese un apellido" value={apellido}
                                            className={apellidoError ? "p-invalid" : ""}
                                        />
                                        <p className="mensajeError">{apellidoError ? mensajeApellido : ""}</p>
                                    </div>
                                </>
                                {
                                    tienePermiso ?
                                        <>

                                            <div className="p-field p-col-12 p-md-3">
                                                <label className="labels" htmlFor="usuario">Horas Mínimas (Diarias)<span className="require">*</span></label>
                                                <InputText name="horaMin" value={hourMin} onChange={onChangeHourMin}
                                                    placeholder="Ingrese las fechas Mínimas" keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    className={errorHour || valueValidNumber ? "p-invalid" : ""}
                                                />
                                                <p className="mensajeError">{errorHour ? messageHourError : ""}{valueValidNumber ? messageValidNumber : ""}</p>
                                            </div>

                                            <div className="p-field p-col-12 p-md-3">
                                                <label className="labels" htmlFor="usuario">Horas Máximas (Diarias)<span className="require">*</span></label>
                                                <InputText name="horaMax" value={hourMax} onChange={onChangeHourMax}
                                                    placeholder="Ingrese las fechas Máximas" keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                    className={errorHourMax || valueValidNumberMax ? "p-invalid" : ""}
                                                />
                                                <p className="mensajeError">{errorHourMax ? messageHourErrorMax : ""}{valueValidNumberMax ? messageValidNumberMax : ""}</p>
                                            </div>
                                        </>
                                        :
                                        ''
                                }
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" htmlFor="cargo">Cargo<span className="require">*</span></label>
                                    <Dropdown value={selectedCargo} options={cargos} onChange={onCargoChange} name="cargo" optionLabel="cargo"
                                        placeholder="Seleccione Cargo" disabled={tipoUsuario === 'Cliente' ? true : false}
                                        className={cargoError ? "p-invalid" : ""} filter
                                    />
                                    <p className="mensajeError">{cargoError ? mensajeCargo : ""}</p>
                                </div>
                                <div className="p-field p-col-12 p-md-6">
                                    <label className="labels" >Fecha de ingreso del usuario {validateSuperAdmin ? '' : <span className="require">*</span>}</label>
                                    <Calendar placeholder="Ingrese una fecha " value={guardarFecha} onChange={onConvertirFechaChange} dateFormat="dd-mm-yy" locale="es"
                                        monthNavigator yearNavigator yearRange="2010:2030" className={dateError ? "p-invalid" : ""}
                                    />
                                    <p className="mensajeError">{dateError ? messageDateError : ""}</p>
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label className="labels" htmlFor="usuario">Usuario</label>
                                    <InputText id="usuario" type="text" name="usuario" keyfilter="email" disabled value={usuarioProp} />
                                </div>
                                <div className="p-field p-col-12 p-md-3">
                                    <label className="labels" htmlFor="contraseña">Contraseña</label>
                                    <InputText id="contraseña" type="password" name="contraseña" onChange={onContraseñaChange} keyfilter="pint" disabled value={contraseña} />
                                </div>
                                {
                                    changeHour ?
                                        <div className="p-field p-col-12 p-md-6">
                                            <label className="labels" >Fecha fin Hmin y Hmax <span className="require">*</span></label>
                                            <Calendar placeholder="Ingrese una fecha " value={dateHistoryHours} onChange={onDateHistoryHoursChange} dateFormat="dd-mm-yy" locale="es"
                                                className={errorDateHistoryHours ? 'p-invalid' : ''}
                                            />
                                            <p className="mensajeError">{errorDateHistoryHours ? messageErrorDateHistoryHours : ""}</p>
                                        </div>
                                        :
                                        ''
                                }
                            </>
                            :
                            <div>

                            </div>
                        }
                    </form>
                </div>
            </Dialog>

        </>
    )


}
export default BtnActualizarUsuario