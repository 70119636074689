import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import '../../assets/css/TabView.css';

/* import TableConsultar from './TableConsultar' */
import TableRegistrar from './TableRegistrar'
import TableRegistrarAdicionales from './TableRegistrarAdicionales'
import moment from 'moment';

import TableNovedades from './TableNovedades'

import Services from '../../service/Services';
import Cookies from 'universal-cookie';
import { CambiarFormatoFecha, SumarDias, RestarMeses, obtenerHoraMinimasPorFecha, getRangeDate } from '../Funciones';
import axios from 'axios';

const cookies = new Cookies();

const Pestaña = ({ permisosProp, indicadorProp }) => {
    var activeIndex = parseInt(indicadorProp)

    const [servicio, setServicio] = useState([]);
    const [servicioCalendario, setServicioCalendario] = useState([]);
    const [servicioCompletado, setServicioCompletado] = useState(false);

    const getServices = new Services();

    const usuario = cookies.get("usuario");

    useEffect(() => {
        const source = axios.CancelToken.source()
        async function fetchData() {
            const currentDate = new Date()
            const threeMonthsBeforeToday = RestarMeses(new Date(), 1)

            const url = process.env.REACT_APP_BASE_URL + '/v1/api/activity/newConsultListActivity'
            const newDateStart = CambiarFormatoFecha(threeMonthsBeforeToday)
            const newDateEnd = CambiarFormatoFecha(currentDate)
            const json = JSON.stringify({
                esadicional: "0",
                fecFinal: newDateEnd,
                fecInicial: newDateStart,
                usuario: usuario
            })
            /* console.log(json) */
            await axios.post(url, json,
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                    },
                    cancelToken: source.token
                })
                .then(response => {
                    if (response !== undefined) {
                        setServicio(response.data)
                        setServicioCompletado(true)
                    }

                })
                .catch(function (error) {
                    //console.log(error.response)
                })
        }
        fetchData()
        /* getServices.getActividades(usuario).then(data => {
            setServicio(data)
            setServicioCompletado(true)
        }); */
        getServices.getCalendarios().then(data => {
            if (data !== undefined) {
                setServicioCalendario(data)
            }
        });

        return () => {
            if (source) {
                source.cancel()
            }
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var arrayFestivos
    var resultFestivos = servicioCalendario.filter(function (objeto) {
        return objeto.pais === 'Colombia'
    })

    resultFestivos.forEach(element => {
        var array = element.fechas.split(',')
        arrayFestivos = array
    })


    const fechaActual = new Date();

    var totalHoras = 0

    const numeroDia = new Date().getDay();

    var subtracDays

    if (numeroDia === 1) {
        subtracDays = 3
    } else if (numeroDia === 0) {
        subtracDays = 2
    } else {
        subtracDays = 1
    }

    let monthAfterToday = fechaActual.getMonth() + 1;
    let dayAfterToday = fechaActual.getDate() - subtracDays;

    if (monthAfterToday < 10) {
        monthAfterToday = '0' + monthAfterToday;
    }

    if (dayAfterToday < 10) {
        dayAfterToday = '0' + dayAfterToday;
    }

    const validateHoliday = monthAfterToday + '/' + dayAfterToday + '/' + fechaActual.getFullYear()
    var holiday = false

    if (arrayFestivos !== undefined) {
        arrayFestivos.forEach(element => {
            if (element === validateHoliday) {
                holiday = true
            }
        })
    }


    if (holiday === true) {
        if (numeroDia === 1) {
            subtracDays = 4
        } else if (numeroDia === 2) {
            subtracDays = 4
        } else if (numeroDia === 0) {
            subtracDays = 3
        } else {
            subtracDays = 2
        }
    }


    let day = fechaActual.getDate() - subtracDays;
    if (day < 10) {
        day = '0' + day;
    }

    let month = fechaActual.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    var aftertoday

    if (new Date().getDate() === 1) {
        aftertoday = moment().endOf('month').subtract(1, 'months').format('YYYY-MM-DD');

    } else {
        aftertoday = fechaActual.getFullYear() + '-' + month + '-' + day
    }

    var newDate = new Date(aftertoday)
    var firstDay = SumarDias(newDate, 1)

    const primerDia = new Date(firstDay.getFullYear(), firstDay.getMonth(), 1)

    let firstMonthDate = primerDia.getMonth() + 1;
    let firstDayDate = primerDia.getDate();

    if (firstMonthDate < 10) {
        firstMonthDate = '0' + firstMonthDate;
    }

    if (firstDayDate < 10) {
        firstDayDate = '0' + firstDayDate;
    }
    const newFirstDay = primerDia.getFullYear() + '-' + firstMonthDate + '-' + firstDayDate

    const fechaIngreso = cookies.get('fechaIngreso')

   
    let cantidadHorasValidar = 0

    const historicoHorasJSON = sessionStorage.getItem("historicoHoras");
    const historicoHoras = JSON.parse(historicoHorasJSON);

    let tieneMinimaCero = false;
    let suma = 0
    if (arrayFestivos !== undefined) {
        const todasLasFechasDelRango = getRangeDate(fechaIngreso >= newFirstDay ? fechaIngreso : newFirstDay, aftertoday);

        todasLasFechasDelRango.forEach(element => {
            const horasMinimasPorFecha = obtenerHoraMinimasPorFecha(historicoHoras, [CambiarFormatoFecha(element)], arrayFestivos)
            if (parseFloat(horasMinimasPorFecha[CambiarFormatoFecha(element)]) !== isNaN && parseFloat(horasMinimasPorFecha[CambiarFormatoFecha(element)]) === 0) {
                tieneMinimaCero = true;
            }
            const horas = parseFloat(horasMinimasPorFecha[CambiarFormatoFecha(element)]);

            // Primero, verifica si 'horas' es NaN. Si es así, no hagas nada.
            if (isNaN(horas)) {
                // Si 'horas' es NaN, simplemente continúa con el siguiente elemento.
                return; // Continúa con la siguiente iteración del bucle forEach.
            }

            // Si 'horas' es igual a 0, establece 'tieneMinimaCero' como verdadero.
            if (horas === 0) {
                tieneMinimaCero = true;
            }

            // Si 'tieneMinimaCero' es falso, suma 'horas' a 'suma'.
            if (!tieneMinimaCero) {
                suma += horas;
            }

        })
        cantidadHorasValidar = suma; // Calcular después del bucle
    }

    const result = servicio.filter(function (object) {
        return object.fecha <= aftertoday && object.fecha >= (fechaIngreso >= newFirstDay ? fechaIngreso : newFirstDay)
    })

    result.map(item => {
        totalHoras = totalHoras + item.numerohoras
        return result
    })



    var mostrarMensaje = false
    //
    var diferenciaHoras = Number((cantidadHorasValidar - totalHoras).toFixed(1));

    if (servicioCompletado === true) {
        if (totalHoras < cantidadHorasValidar) {
            mostrarMensaje = true
        }

        if (fechaIngreso >= aftertoday) {
            mostrarMensaje = false
        }
    }


    return (
        <div className="tabview-demo">
            <div className="card">
                <TabView activeIndex={activeIndex}>
                    {/* <TabPanel header="Consultar Actividades">
                        <TableConsultar />
                    </TabPanel> */}
                    <TabPanel header="Registrar Actividades">
                        <TableRegistrar
                            permisosProp={permisosProp}
                            mostrarMensajeProp={mostrarMensaje}
                            rangoFechaInicioProp={fechaIngreso >= newFirstDay ? fechaIngreso : newFirstDay}
                            rangoFechaFinProp={aftertoday}
                            diferenciaHorasProp={diferenciaHoras}
                        />
                    </TabPanel>
                    <TabPanel header="Registrar actividades Adicionales">
                        <TableRegistrarAdicionales
                            permisosProp={permisosProp}
                        />
                    </TabPanel>

                    <TabPanel header="Novedades">

                        <TableNovedades
                            permisosProp={permisosProp}
                        />
                    </TabPanel>
                </TabView>
            </div>

        </div>
    )
}

export default Pestaña