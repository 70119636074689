import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { AutoComplete } from 'primereact/autocomplete'
import Services from '../../service/Services';
import ConfirmBtn from '../ConsultarActividades/ConfirmBtn';

const BtnAsociarActividades = ({ selectedProyectoProp, dataServicesProp, 
    runRenderProp, setRunRenderProp, setSelectedActivitiesProp }) => {

    const [displayBasic, setDisplayBasic] = useState(false)
    const [validateChange, setValidateChange] = useState(false)
    const [objectivesService, setObjectivesServices] = useState([])
    const [achievementServices, setAchievementServices] = useState([])
    const [deliverablesServices, setDeliverablesServices] = useState([])

    const getServices = new Services();

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }


    const ModalRegresar = () => {
        const [displayConfirmation, setDisplayConfirmation] = useState(false);

        const [setPosition] = useState('center');

        const dialogFuncMap = {
            'displayConfirmation': setDisplayConfirmation
        }

        const onClick = (name, position) => {
            dialogFuncMap[`${name}`](true);

            if (position) {
                setPosition(position);
            }
        }

        const onHide = (name) => {
            dialogFuncMap[`${name}`](false);
        }

        const Regresar = (name) => {
            dialogFuncMap[`${name}`](false);
            setSelectedObjectivesServiceComplete(null)
            setSelectedAchievementServiceComplete(null)
            setSelectedDeliverablesServiceComplete(null)
            setDisplayBasic(false)
            setValidateChange(false)

        }

        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                    <Button label="Aceptar" icon="pi pi-check" onClick={() => Regresar(name)} autoFocus />
                </div>
            );
        }
        return (
            <>
                <Button label="Regresar" icon="pi pi-times" onClick={() => onClick('displayConfirmation')} className="p-button-text" />
                <Dialog className="dialog-demo" header="Advertencia" visible={displayConfirmation} modal style={{}}
                    footer={renderFooter('displayConfirmation')} onHide={() => ('displayConfirmation')}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        <span style={{ textAlign: 'center' }}>No se ha guardado información registrada, ¿desea continuar?</span>
                    </div>
                </Dialog>
            </>
        )
    }

    useEffect(() => {
        getServices.getObjectivesShortList(selectedProyectoProp?.map(item => {
            return item.id
        })).then((result) => {
            setObjectivesServices(result)
        }).catch((error) => {

        })


    }, [selectedProyectoProp])// eslint-disable-line react-hooks/exhaustive-deps

    //Listado de Objetivos asociados al proyecto seleccionado
    const [selectedObjectivesServiceComplete, setSelectedObjectivesServiceComplete] = useState(null);
    const [filteredObjectivesService, setFilteredObjectivesService] = useState(null);

    //// llenar objetivos  asociados al proyecto seleccionado

    const tableObjectivesServices = {}
    const uniqueObjectivesServices = objectivesService?.filter(function (object) {
        return tableObjectivesServices.hasOwnProperty(object.id) ? false : (tableObjectivesServices[object.id] = true)
    })
    const searchObjectives = (event) => {
        setTimeout(() => {
            let _filteredObjectives;
            if (!event.query.trim().length) {
                _filteredObjectives = [...uniqueObjectivesServices];
            }
            else {
                _filteredObjectives = uniqueObjectivesServices?.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredObjectivesService(_filteredObjectives);
        }, 250);
    }

    const objectivesOnChange = (e) => {
        setSelectedObjectivesServiceComplete(e.value)
        setValidateChange(true)
        if (e.value !== null) {
            getServices.getAchievementListShort(e.value.id).then((result => {
                setAchievementServices(result)
            })).catch((error) => {

            })

            getServices.getDeliverablesListShort(e.value.id).then((result => {
                setDeliverablesServices(result)
            })).catch((error) => {

            })
        }
    }

    //Listado de logros asociados al objetivo seleccionado

    const [selectedAchievementServiceComplete, setSelectedAchievementServiceComplete] = useState(null);
    const [filteredAchievementService, setFilteredAchievementService] = useState(null);

    const tableAchievementServices = {}
    const uniqueAchievementServices = achievementServices?.filter(function (object) {
        return tableAchievementServices.hasOwnProperty(object.id) ? false : (tableAchievementServices[object.id] = true)
    })

    const searchAchievement = (event) => {
        setTimeout(() => {
            let _filteredAchievement;
            if (!event.query.trim().length) {
                _filteredAchievement = [...uniqueAchievementServices];
            }
            else {
                _filteredAchievement = uniqueAchievementServices?.filter((element) => {
                    return element.logro.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredAchievementService(_filteredAchievement);
        }, 250);
    }

    const achievementOnChange = (e) => {
        setSelectedAchievementServiceComplete(e.value)
        setValidateChange(true)
    }

    //Listado de entregables asociados al objetivo seleccionado

    const [selectedDeliverablesServiceComplete, setSelectedDeliverablesServiceComplete] = useState(null);
    const [filteredDeliverablesService, setFilteredDeliverablesService] = useState(null);

    const tableDeliverableServices = {}
    const uniqueDeliverablesServices = deliverablesServices?.filter(function (object) {
        return tableDeliverableServices.hasOwnProperty(object.id) ? false : (tableDeliverableServices[object.id] = true)
    })

    const searchDeliverables = (event) => {
        setTimeout(() => {
            let _filteredDeliverables;
            if (!event.query.trim().length) {
                _filteredDeliverables = [...uniqueDeliverablesServices];
            }
            else {
                _filteredDeliverables = uniqueDeliverablesServices?.filter((element) => {
                    return element.descripcion.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredDeliverablesService(_filteredDeliverables);
        }, 250);
    }

    const deliverablesOnChange = (e) => {
        setSelectedDeliverablesServiceComplete(e.value)
        setValidateChange(true)
    }

    let disabledObjectives = false
    selectedProyectoProp?.forEach(i => {
        if (i.id === '' || i.id === null) {
            disabledObjectives = true
        } else {
            disabledObjectives = false
        }
    })


    let disabledAchivementAndDeliverable = false
    if (selectedObjectivesServiceComplete === null) {
        disabledAchivementAndDeliverable = true
    } else {
        disabledAchivementAndDeliverable = false
    }

    let permisoActualizar = false
    if (selectedObjectivesServiceComplete !== null) {
        permisoActualizar = true;
    }

    const allData = dataServicesProp.map(i => ({
        actualizadopor: i.actualizadopor,
        aplicacion: {
            id: i.aplicacion.id
        },
        areanegocio: {
            id: i.areanegocio.id,
        },
        cliente: {
            id: i.cliente.id
        },
        colaborador: {
            id: i.colaborador.id.toString()
        },
        coordinadocon: i.coordinadocon,
        creadopor: i.creadopor,
        descripcionactividad: i.descripcionactividad,
        esadicional: i.esadicional,
        estado: 1,
        fecha: i.fecha,
        fechaactualizado: new Date(),
        fechacorreoelec: "",
        fechacreacion: i.fechacreacion,
        id: i.id,
        idempresa: {
            id: i.idempresa.id
        },
        numerohoras: i.numerohoras,
        proyecto: {
            id: i.proyecto.id
        },
        tipoActividad: {
            id: i.tipoActividad.id
        },
        ticket: i.ticket,
        notas: i.notas,
        idproveedor: i.idproveedor,
        idObjetivo: selectedObjectivesServiceComplete !== null ? parseInt(selectedObjectivesServiceComplete?.id) : null,
        idLogro: selectedAchievementServiceComplete !== null ? parseInt(selectedAchievementServiceComplete?.id) : null,
        idEntregable: selectedDeliverablesServiceComplete !== null ? parseInt(selectedDeliverablesServiceComplete?.id) : null,
    }));

    const renderFooter = (name) => {
        return (
            <div>
                {
                    validateChange ?
                        <ModalRegresar displayBasicProp={false} />
                        :
                        <Button label="Regresar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                }
                {

                    permisoActualizar ?

                        <ConfirmBtn
                            titulo="Actualizar Registro"
                            mensaje="¿Estás seguro de actualizar Actividad?"
                            accion="Actualizar"
                            label="Actualizar"
                            icono="pi pi-pencil"
                            allDataProp={allData}
                            BtnAsociarActividadesProp={true}
                            runRenderProp={runRenderProp}
                            setRunRenderProp={setRunRenderProp}
                            setDisplayBasicProp={setDisplayBasic}
                            setSelectedActivitiesProp={setSelectedActivitiesProp}
                        />
                        :
                        ''
                }

            </div>
        )
    }

    return (
        <>
            <Button label="Asociar Actividades" icon="pi pi-plus" className="p-button-success" onClick={() => {
                onClick('displayBasic')
            }} />

            <Dialog className="modal" header='Asociar Actividades' visible={displayBasic} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic')} >
                <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-4">
                        <label className="labels" >Objetivo</label>
                        <AutoComplete value={selectedObjectivesServiceComplete} suggestions={filteredObjectivesService} completeMethod={searchObjectives} field="descripcion"
                            onChange={objectivesOnChange} placeholder="Seleccione un Objetivo" dropdown forceSelection filterMatchMode="startsWith"
                            disabled={disabledObjectives} className={"auto"}
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-4">
                        <label className="labels" >Logro</label>
                        <AutoComplete value={selectedAchievementServiceComplete} suggestions={filteredAchievementService} completeMethod={searchAchievement} field="logro"
                            onChange={achievementOnChange} placeholder="Seleccione un Logro" dropdown forceSelection filterMatchMode="startsWith"
                            disabled={disabledAchivementAndDeliverable} className={"auto"}
                        />
                    </div>

                    <div className="p-field p-col-12 p-md-4">
                        <label className="labels" >Entregable</label>
                        <AutoComplete value={selectedDeliverablesServiceComplete} suggestions={filteredDeliverablesService} completeMethod={searchDeliverables} field="descripcion"
                            onChange={deliverablesOnChange} placeholder="Seleccione un Entregable" dropdown forceSelection filterMatchMode="startsWith"
                            disabled={disabledAchivementAndDeliverable} className={"auto"}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default BtnAsociarActividades